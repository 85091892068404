import { ActionContext, ActionTree } from "vuex";
import ApiService from "@/services/ApiService";
import ArchivApiService from "@/services/ArchivApiService";
import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import { RootState } from '@/store';
import { State } from "./state"
import router from "@/router";

import { Mutations, MutationTypes } from "./mutations";
import { AuthUser, LoginRequest, LoginAzureRequest, AzureConfigEinstellung, RefreshLoginRequest, LogoutRequest } from "@/models/Archiv/ArchivModels";
import { ErrorResponseData } from "@/models/Common/CommonModels";

export enum ActionTypes {
  Login = "LOGIN",
  Logout = "LOGOUT",
  LoginAzure = "LOGINAZURE",
  ValidateOTP = "VALIDATEOTP",
  RefreshLogin = "REFRESHLOGIN",
  ZelosLogin = "ZELOSLOGIN",
  ZelosLogout = "ZELOSLOGOUT",
  ZelosRefreshLogin = "ZELOSREFRESHLOGIN",
  GetAzureLoginConfig = "GETAZURELOGINCONFIG",
  SetAzureLoginAvailable = "SETAZURELOGINAVAILABLE",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.Login](context: ActionArguments, data: LoginRequest): Promise<void>
  [ActionTypes.LoginAzure](context: ActionArguments, azureLogin: LoginAzureRequest): Promise<void>
  [ActionTypes.Logout](context: ActionArguments): Promise<void>
  [ActionTypes.ValidateOTP](context: ActionArguments, data: LoginRequest): Promise<void>
  [ActionTypes.RefreshLogin](context: ActionArguments): Promise<void>
  [ActionTypes.ZelosLogin](context: ActionArguments): Promise<void>
  [ActionTypes.ZelosLogout](context: ActionArguments): Promise<void>
  [ActionTypes.ZelosRefreshLogin](context: ActionArguments): Promise<void>
  [ActionTypes.GetAzureLoginConfig](context: ActionArguments): void
  [ActionTypes.SetAzureLoginAvailable](context: ActionArguments): void
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.Login]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;

    status.authLoading = true;
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.login(data)
      .then(async (res) => {
        const user: AuthUser = res.data;
        commit(MutationTypes.LoginSuccess, user);
        localStorage.setItem("authUser", JSON.stringify(user));
        status.archivLoggedIn = true;
        
        await dispatch(ActionTypes.ZelosLogin, undefined)
        if (status.authErrorMsg == "") {
          status.authLoading = false;
          dispatch(CommonActionTypes.SetStatus, status);
          router.push({name: "Versender"});
        }
        else {
          status.authLoading = false;
          dispatch(CommonActionTypes.SetStatus, status);
          // dispatch(ActionTypes.Logout, undefined)
          // .then(() => {
          //   router.push({name: "Logout"});
          // });
        }
      })
      .catch(error => {
        var errorresponse = error.response?.data as ErrorResponseData || null
        status.authErrorMsg = errorresponse.title;
        status.authLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
        localStorage.removeItem('authUser');
      });
  },

  async [ActionTypes.Logout]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;
    status.authLoading = true;
    status.authErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.logout(new LogoutRequest(rootGetters.authUser.refreshToken))
      .then(() => {
        // console.log("LOGOUT");
      })
      .catch(error => {
        // console.log("LOGOUT CATCH");
        // console.log(error);
      })
      .finally(async () => {
        status.authLoading = false;
        status.archivLoggedIn = false;
        dispatch(CommonActionTypes.SetStatus, status);
        dispatch(ActionTypes.ZelosLogout, undefined);
        commit(MutationTypes.SetAzureLogin, false)
        commit(MutationTypes.Logout, undefined);
        sessionStorage.clear();
        let azureconfig = rootGetters.azureconfig;
        let msalConfig = rootGetters.msalConfig;
        let secureBrowserToken = localStorage.getItem("SECBROWSTOKEN")

        localStorage.clear();
        localStorage.setItem("azureconfig", JSON.stringify(azureconfig))
        localStorage.setItem("msalconfig", JSON.stringify(msalConfig))

        if (secureBrowserToken != null) {
          localStorage.setItem("SECBROWSTOKEN", secureBrowserToken);
        }
      })
  },

  [ActionTypes.LoginAzure]({commit, dispatch, rootGetters}, azureLogin) {
    const status = rootGetters.status;

    status.authLoading = true;
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetAzureUsername, azureLogin.username)
    localStorage.setItem('azureusername', JSON.stringify(azureLogin.username));

    return ArchivApiService.loginAzure(azureLogin.idToken)
      .then(async (res) => {
        commit(MutationTypes.SetAzureLogin, true)
        
        const user: AuthUser = res.data;
        commit(MutationTypes.LoginSuccess, user);
        localStorage.setItem("authUser", JSON.stringify(user));
        
        await dispatch(ActionTypes.ZelosLogin, undefined)
        
        status.authLoading = false;
        status.archivLoggedIn = true;
        dispatch(CommonActionTypes.SetStatus, status);

        router.push({name: "Versender"});

      })
      .catch(error => {
        status.authLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);

        // commit(MutationTypes.AuthRequestFailure, error.response.data.title);
        commit(MutationTypes.AuthRequestFailure, "Anmeldung fehlgeschlagen");
        localStorage.removeItem('authUser');
      })
  },

  async [ActionTypes.ValidateOTP]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.authLoading = true;
    status.authErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ArchivApiService.validateOTPLogin(data)
      .then(async (res) => {
        const user: AuthUser = res.data;
        commit(MutationTypes.LoginSuccess, user);
        localStorage.setItem("authUser", JSON.stringify(user));
        status.archivLoggedIn = true;
        
        await dispatch(ActionTypes.ZelosLogin, undefined)
        if (status.authErrorMsg == "") {
          status.authLoading = false;
          dispatch(CommonActionTypes.SetStatus, status);
          router.push({name: "Versender"});
        }
        else {
          status.authLoading = false;
          dispatch(CommonActionTypes.SetStatus, status);
          // dispatch(ActionTypes.Logout, undefined)
          // .then(() => {
          //   router.push({name: "Logout"});
          // });
        }
      })
      .catch(error => {
        var errorresponse = error.response?.data as ErrorResponseData || null
        status.authErrorMsg = errorresponse.title;
        localStorage.removeItem('authUser');
      })
      .finally(() => {
        status.authLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      });
  },

  async [ActionTypes.RefreshLogin]({commit, dispatch, rootGetters}) {
    const request = new RefreshLoginRequest(rootGetters.authUser.refreshToken);
    const res = await ArchivApiService.refreshLogin(request);

    const result: AuthUser = res.data;
    commit(MutationTypes.RefreshLoginSuccess, result)
    localStorage.setItem("authUser", JSON.stringify(result));

    return
  },

  async [ActionTypes.GetAzureLoginConfig] ({commit}) {
    ArchivApiService.getAzureLoginConfig()
    .then(res => {
      const azureconfig: AzureConfigEinstellung = res.data
      commit(MutationTypes.GetAzureLoginConfigSuccess, azureconfig)
      localStorage.setItem('azureconfig', JSON.stringify(azureconfig));
    })
    .catch(error => {
      localStorage.removeItem('azureconfig');
    })
  },

  async [ActionTypes.ZelosLogin]({commit, dispatch, rootGetters}) {
    const status = rootGetters.status;

    return ApiService.loginZelos()
      .then(res => {
        const user: AuthUser = res.data;
        commit(MutationTypes.ZelosLoginSuccess, user);
        localStorage.setItem("zelosAuthUser", JSON.stringify(user));
      })
      .catch(error => {
        localStorage.removeItem('zelosAuthUser');
        if (error.code == "ERR_NETWORK") {
          status.authErrorMsg = error.message;
        }
        else {
          status.authErrorMsg = error.toString()
        }
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.ZelosLogout]({commit, dispatch, rootGetters}) {
    await  ApiService.logoutZelos(new LogoutRequest(rootGetters.authUser.refreshToken));
    commit(MutationTypes.ZelosLogout, undefined);

    return;
  },

  async [ActionTypes.ZelosRefreshLogin]({commit, dispatch, rootGetters}) {
    const request = new RefreshLoginRequest(rootGetters.zelosAuthUser.refreshToken);
    const res = await ApiService.refreshLoginZelos(request);

    const result: AuthUser = res.data;
    commit(MutationTypes.ZelosRefreshLoginSuccess, result)
    localStorage.setItem("zelosAuthUser", JSON.stringify(result));

    return
  },

  [ActionTypes.SetAzureLoginAvailable] ( {commit}) {
    commit(MutationTypes.SetAzureLoginAvailable, undefined);
  }
}