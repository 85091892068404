import { MutationTree } from "vuex"
import { State } from "./state"
import { CEadVal, CEadValPos, Entwurf, NEadSubPos } from "@/models/Zelos/ZelosModels"
import { ArchivComponente } from "@/models/Archiv/ArchivModels"

export enum MutationTypes {
  SetEntwurfe = "SETENTWURFE",
  UpdateNEadSub = "UPDATENEADSUB",
  SetArchivComponente = "SETARCHIVCOMPONENTE",
  AddNEadSubPosRange = "ADDNEADSUBPOSRANGE",
  SetCEadVal = "SETCEADVAL",
  AddCEadValPosRange = "ADDCEADVALPOSRANGE"
}

export type Mutations = {
  [MutationTypes.SetEntwurfe](state: State, entwurfe: Array<Entwurf>): void
  [MutationTypes.UpdateNEadSub](state: State, data: Entwurf): void
  [MutationTypes.SetArchivComponente](state: State, comp: ArchivComponente): void
  [MutationTypes.AddNEadSubPosRange](state: State, data: Array<NEadSubPos>): void
  [MutationTypes.SetCEadVal](state: State, data: Array<CEadVal>): void
  [MutationTypes.AddCEadValPosRange](state: State, data: Array<CEadValPos>): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetEntwurfe](state, entwurfe) {
    state.entwurfe = entwurfe;
    sessionStorage.setItem("neadsub", JSON.stringify(state.entwurfe));
  },

  [MutationTypes.UpdateNEadSub](state, data) {
    const olddata = state.entwurfe.find(a => a.id === data.id)
    if (olddata != null) {
      const index = state.entwurfe.indexOf(olddata)
      state.entwurfe.splice(index, 1, data)
    }

    sessionStorage.setItem("neadsub", JSON.stringify(state.entwurfe));
  },
  
  [MutationTypes.SetArchivComponente](state, comp) {
    state.archivComponente = comp
  },
  
  [MutationTypes.AddNEadSubPosRange](state, data) {
    state.neadsubpos.push(...data)
    sessionStorage.setItem("neadsubpos", JSON.stringify(state.neadsubpos));
  },

  [MutationTypes.SetCEadVal](state, data) {
    state.ceadval = data;
    sessionStorage.setItem("ceadval", JSON.stringify(state.ceadval));
  },
  
  [MutationTypes.AddCEadValPosRange](state, data) {
    state.ceadvalpos.push(...data)
    sessionStorage.setItem("ceadvalpos", JSON.stringify(state.ceadvalpos));
  }
}