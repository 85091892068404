export class Status {
  errorMsg: string;
  loading: boolean;

  archivLoggedIn: boolean;
  authErrorMsg: string;
  authLoading: boolean;

  evdLoading: boolean
  evdCompLoading: boolean
  evdErrorMsg: string;

  //#region NEadSub
  getNeadsubLoading: boolean;
  createNeadsubLoading: boolean;
  updateNeadsubLoading: boolean;
  deleteNeadsubLoading: boolean;
  neadsubErrorMsg: string;
  //#endregion

  //#region NEadSubPos
  getNeadsubposLoading: boolean;
  createNeadsubposLoading: boolean;
  updateNeadsubposLoading: boolean;
  deleteNeadsubposLoading: boolean;
  neadsubposErrorMsg: string;
  //#endregion

  getCeadvalLoading: boolean;
  ceadvalErrorMsg: string;

  getCeadvalposLoading: boolean;
  ceadvalposErrorMsg: string;

  sidebarCollapsed: boolean;

  constructor () {
    this.errorMsg = "";
    this.loading = false;

    this.archivLoggedIn = false;

    this.authLoading = false;
    this.authErrorMsg = "";

    this.evdLoading = false;
    this.evdCompLoading = false;
    this.evdErrorMsg = "";

    //#region NEadSub
    this.getNeadsubLoading = false;
    this.createNeadsubLoading = false;
    this.updateNeadsubLoading = false;
    this.deleteNeadsubLoading = false;
    this.neadsubErrorMsg = "";
    //#endregion

    //#region NEadSubPos
    this.getNeadsubposLoading = false;
    this.createNeadsubposLoading = false;
    this.updateNeadsubposLoading = false;
    this.deleteNeadsubposLoading = false;
    this.neadsubposErrorMsg = "";
    //#endregion

    this.getCeadvalLoading = false;
    this.ceadvalErrorMsg = "";

    this.getCeadvalposLoading = false;
    this.ceadvalposErrorMsg = "";

    this.sidebarCollapsed = false;

  }
}

export interface ErrorResponseData {
  detail: string
  status: number
  title: string
  type: string
}