import { ArchivComponente } from "@/models/Archiv/ArchivModels"
import { CEadVal, CEadValPos, Entwurf, NEadSubPos } from "@/models/Zelos/ZelosModels"

export type State = {
  entwurfe: Array<Entwurf>,
  archivComponente: ArchivComponente,
  neadsubpos: Array<NEadSubPos>,
  ceadval: Array<CEadVal>,
  ceadvalpos: Array<CEadValPos>,
}

export const state: State = {
  entwurfe: sessionStorage.getItem("neadsub") != null ? JSON.parse(sessionStorage.neadsub) : new Array<Entwurf>(),
  archivComponente: new ArchivComponente(),
  neadsubpos: sessionStorage.getItem("neadsubpos") != null ? JSON.parse(sessionStorage.neadsubpos) : new Array<NEadSubPos>(),
  ceadval: sessionStorage.getItem("ceadval") != null ? JSON.parse(sessionStorage.ceadval) : new Array<CEadVal>(),
  ceadvalpos: sessionStorage.getItem("ceadvalpos") != null ? JSON.parse(sessionStorage.ceadvalpos) : new Array<CEadValPos>()
}
