<template>
  <div :id="'bezugsnummer-' + entwurf.eaddraftLocalrefnr" class="mb-2 mx-1 shadow-sm rounded-0 py-2" :class="statusBGClass">
    <div  class="row">
      <div class="col-1 my-auto">{{ entwurf.eaddraftLocalrefnr }}</div>
      <div class="col-1 my-auto">{{ statusConverter }}</div>
      
      <div class="col-2 my-auto">{{ entwurf.consigneetraderTrader }}</div>
      <div class="col-2 my-auto">{{ entwurf.deliveryplacetraderTrader }}</div>
      
      <div class="col-1 my-auto">{{ entwurf.deliveryplacetraderTraderid }}</div>
      <div class="col-2 my-auto">
        <div v-if="lkwKennzeichenEditMode">
          <form @submit.prevent="setLkwKennzeichen">
            <div class="input-group">
              <input type="text" class="form-control" v-model="lkwKennzeichenEdit" aria-label="LKW Kennzeichen">
              <button type="submit" class="btn btn-primary" id="button-addon">
                <div v-if="updateNeadsubLoading && currentLoading">
                  <span class="spinner-border spinner-border-sm text-light" role="status"></span>
                </div>
                <div v-else>
                  <font-awesome-icon icon="floppy-disk" />
                </div>
              </button>
              <button type="button" class="btn btn-outline-secondary" id="button-addon" @click="closeLkwKennzeichenEditMode()"><font-awesome-icon icon="xmark" /></button>
            </div>
            <span v-if="neadsubErrorMsg != ''" class="text-danger">Es ist ein Fehler aufgetreten!</span>
          </form>
        </div>
        <div v-else class="d-flex align-items-center justify-content-between">
          <span>{{ entwurf.transportdetailsIdoftransunit }}</span> <span class="bestellung text-secondary" @click="toggleLkwKennzeichenEditMode()"><font-awesome-icon icon="pen" /></span>
        </div>
      </div>
      <div class="col-1 my-auto">{{ entwurf.lastmessagetype }}</div>
      <div class="col-1 my-auto">{{ zeitstempelConverter}}</div>

      <div class="col-1 my-auto text-center">
        <div v-if="entwurf.status == 0" class="btn-group">
          <button  class="btn btn-secondary" data-bs-toggle="collapse" :data-bs-target="'#details-' + entwurf.eaddraftLocalrefnr" @click="getNEadSubPos()">
            <font-awesome-icon icon="magnifying-glass-plus" />
          </button>

          <button  class="btn btn-primary" @click="senden()"> 
            <div v-if="currentLoading && updateNeadsubLoading" class="spinner-border spinner-border-sm text-light" role="status">
              <span class="sr-only"></span>
            </div>
            <font-awesome-icon v-else icon="paper-plane" />
          </button>
        </div>

        <div v-else-if="entwurf.status == 2" class="btn-group">
          <button class="btn btn-primary">
            <font-awesome-icon icon="magnifying-glass-plus" data-bs-toggle="collapse" :data-bs-target="'#details-' + entwurf.eaddraftLocalrefnr" @click="getNEadSubPos()" />
          </button>

          <button class="btn btn-secondary" :disabled="evdCompLoading" @click="downloadAndShowComponent()">
            <div v-if="currentLoading" class="spinner-border spinner-border-sm text-light" role="status">
              <span class="sr-only"></span>
            </div>
            <font-awesome-icon v-else icon="print" />
          </button>
          

          <!-- <button class="btn btn-success" :disabled="evdCompLoading" @click="drucken()">
            <div v-if="currentLoading" class="spinner-border spinner-border-sm text-light" role="status">
              <span class="sr-only"></span>
            </div>
            <font-awesome-icon v-else icon="envelope" class="fa-lg" />
          </button> -->
        </div>

        <button v-else class="btn btn-secondary" data-bs-toggle="collapse" :data-bs-target="'#details-' + entwurf.eaddraftLocalrefnr" @click="getNEadSubPos()">
          <font-awesome-icon icon="magnifying-glass-plus" />
        </button>
      </div>
    </div>

    <div :id="'details-' + entwurf.eaddraftLocalrefnr" class="mt-3 pt-2 border-top border-secondary collapse">
      <LoadingSpinner v-if="getNeadsubposLoading" text="... Positionsdaten werden geladen ..." />

      <div v-else-if="!getNeadsubposLoading && positionen.length == 0 && neadsubposErrorMsg == ''" class="alert alert-info">
        Es wurden keine Positionen gefunden
      </div>

      <div v-else-if="!getNeadsubposLoading && positionen.length > 0 && neadsubposErrorMsg == ''">
        <div class="row mt-2">
          <div class="col-1"><strong>Pos</strong></div>
          <div class="col-3"><strong>Artikel</strong></div>
          <div class="col-1"><strong>Anzahl</strong></div>
          <div class="col-1"><strong>Alk %</strong></div>
          <div class="col-1"><strong>Brutto [Kg]</strong></div>
          <div class="col-1"><strong>Netto [Kg]</strong></div>
          <div class="col-4"></div>
        </div>

        <div v-for="(pos, idx) in positionen" :key="pos.id + '-' + idx" class="row mb-3">
          <div class="col-1">{{ pos.eadbodyBodyrecorduniqueref }}</div>
          <div class="col-3">{{ pos.eadbodyBrandnameofproducts }}</div>
          <div class="col-1">{{ pos.eadbodyQuantity }}</div>
          <div class="col-1">{{ pos.eadbodyAlcoholicstrength }}</div>
          <div class="col-1">{{ pos.eadbodyGrossmass }}</div>
          <div class="col-1">{{ pos.eadbodyNetmass }}</div>
          <div class="col-4"></div>
        </div>
      </div>

      <div v-else class="alert alert-danger">
        Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
      </div>

    </div>
  </div>

    <!-- PDF Preview Modal -->
  <div
    class="modal fade p-0"
    :id="'ordnerDokumentPDFModal' + entwurf.eaddraftLocalrefnr"
    tabindex="-1"
    :aria-labelledby="'ordnerDokumentPDFModal' + entwurf.eaddraftLocalrefnr"
    aria-hidden="true"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    ref="ordnerDokumentPDFModalRef"
  >
    <div class="modal-dialog modal-dialog-centerd modal-xl my-0 h-100">
      <div class="modal-content h-100">
        <div class="modal-header py-1">
          <div class="w-100 d-flex justify-content-between me-3">
            <h5 class="modal-title my-auto" :id="'ordnerDokumentPDFModalLabel' + entwurf.eaddraftLocalrefnr">
              {{entwurf.eaddraftLocalrefnr}}
            </h5>
            
            <!-- <button class="btn btn-outline-dark my-auto" @click="showSendEmailModal()">
              <font-awesome-icon icon="envelope" class="me-3"/>
              An private E-Mail senden
            </button> -->
          </div>
          <button
          type="button"
          class="btn-close"
          @click="closePdfModal()"
          ></button>
        </div>

        <div class="modal-body p-0" :id="'ordnerDokumentPDFModalBody' + entwurf.eaddraftLocalrefnr">
          <div v-if="comploaded">
            <PDFViewer 
              ref="pdfViewerRef"
              :pdfUrl="pdfUrl"
              :docId="entwurf.eaddraftLocalrefnr"
              :pdfName="componente.filename"
              :options="pdfViewerOptions"
              @sendDocAsEmail="showSendEmailModal()" />
          </div>
          <div v-if="evdCompLoading">
            <LoadingSpinner />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from "vue";
import { useStore } from '@/store'
import { ActionTypes as ZelosActionTypes } from "@/store/modules/Zelos/actions";
import { ActionTypes } from "@/store/modules/Zelos/actions";
import { SendEVDRequest, ExecuteExportRequest, Entwurf, GetEVDDocCompRequest, NEadSubPos, NeadsubKennzeichenRequest} from "@/models/Zelos/ZelosModels"
import { ArchivDownloadCompRequest } from "@/models/Archiv/ArchivModels"

import { Modal } from 'bootstrap';
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import PDFViewer from "@/components/PDFViewer/PDFViewer.vue";
import { PDFViewerOptions } from '@/components/PDFViewer/PDFViewerClasses'

const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 
const PDFVIEWERDEFAULTSCALE = config.pdfViewerDefaultScale;

export default defineComponent({
  name: "PostfachNachricht",
  props: {
    entwurf: {
      type: Object as PropType<Entwurf>,
      required: true
    }
  },

  emits: ['toggleTimer'],

  components: {
    LoadingSpinner,
    PDFViewer
  },

  setup(props) {
    const store = useStore();
    const evdCompLoading = computed(() => store.getters.status.evdCompLoading)
    const currentLoading = ref(false)

    const authUser = computed(() => store.getters.authUser)
    const zeitstempelConverter = computed(() => {
      if (props.entwurf.lastmessagetimestamp == null) {
        return "";
      }

      const timestamp = new Date(props.entwurf.lastmessagetimestamp);
      return ("0" + timestamp.getDate()).slice(-2) + "." + ("0" + (timestamp.getMonth() + 1)).slice(-2) + "." + timestamp.getFullYear() + " " + ("0" + (timestamp.getHours())).slice(-2) + ":" + ("0" + (timestamp.getMinutes())).slice(-2) + ":" + ("0" + (timestamp.getSeconds())).slice(-2);
    })

    const positionen = computed(() => store.getters.neadsubpos.filter((p: NEadSubPos) => p.nEadSubId == props.entwurf.id))
    const getNeadsubposLoading = computed(() => store.getters.status.getNeadsubposLoading);
    const neadsubposErrorMsg = computed(() => store.getters.status.neadsubposErrorMsg);
    
    const updateNeadsubLoading = computed(() => store.getters.status.updateNeadsubLoading);
    const neadsubErrorMsg = computed(() => store.getters.status.neadsubErrorMsg);

    const componente = computed(() => store.getters.archivComponente);
    const comploaded = ref(false)

    const pdfUrl =ref("")
    const pdfViewerOptions = ref(new PDFViewerOptions())

    const statusConverter = computed(() => {
      let status = "undefined"
      switch(props.entwurf.status) {
        case 0: status = "Offen"; break;
        case 1: status = "Gesendet"; break;
        case 2: status = "Genehmigt"; break;
        case 3: status = "Abgelehnt"; break;
        case 4: status = "Annulliert"; break;
        case 5: status = "Abgebrochen"; break;
      }

      return status;
    })

    const statusBGClass = computed(() => {
      let status = "bg-light"
      switch(props.entwurf.status) {
        case 0: status = "alert alert-light"; break;
        // case 1: status = "bg-warning bg-opacity-50"; break;
        case 1: status = "alert alert-warning"; break;
        case 2: status = "alert alert-success"; break;
        case 3: status = "alert alert-danger"; break;
        case 4: status = "alert alert-danger"; break;
        case 5: status = "alert alert-danger"; break;
      }

      return status;
    })

    const pdfViewerRef = ref();
    const ordnerDokumentPDFModalRef = ref();
    const closeModal = () => {
      pdfViewerRef.value?.clearViewer()
      Modal.getInstance(ordnerDokumentPDFModalRef.value)?.hide();
    }
    const showModal = async () => {
      if (ordnerDokumentPDFModalRef.value) {
        new Modal(ordnerDokumentPDFModalRef.value).show();

        // await pdfViewerRef.value.loadPDF()
      }
    }

    const lkwKennzeichenEdit = ref(props.entwurf.transportdetailsIdoftransunit)
    const lkwKennzeichenEditMode = ref(false)
    function toggleLkwKennzeichenEditMode () {
      lkwKennzeichenEditMode.value = !lkwKennzeichenEditMode.value
    }

    function closeLkwKennzeichenEditMode () {
      lkwKennzeichenEdit.value = props.entwurf.transportdetailsIdoftransunit;
      lkwKennzeichenEditMode.value = false;
    }

    return {
      store,
      evdCompLoading,
      currentLoading,
      comploaded,
      pdfUrl,
      authUser,
      zeitstempelConverter,
      componente,
      statusConverter,
      statusBGClass,
      ordnerDokumentPDFModalRef,
      closeModal,
      showModal,
      pdfViewerOptions,
      positionen,
      getNeadsubposLoading,
      neadsubposErrorMsg,
      updateNeadsubLoading,
      neadsubErrorMsg,
      lkwKennzeichenEdit,
      lkwKennzeichenEditMode,
      toggleLkwKennzeichenEditMode,
      closeLkwKennzeichenEditMode,
    }
  },

  methods: {
    getNEadSubPos() {
      if (this.positionen.length == 0) {
        this.store.dispatch(ZelosActionTypes.GetNEadSubPosByNEadSubId, this.entwurf.id)
      }
    },

    senden () {
      this.currentLoading = true;
      const data = new ExecuteExportRequest(this.entwurf.headerMessagesender, this.entwurf.eaddraftLocalrefnr, "N_EAD_SUB")

      this.store.dispatch(ActionTypes.SendBezugszunnumer, data)
      .then(async () => {
        await this.store.dispatch(ActionTypes.GetNeadsubByEaddraftlocalrefnr, this.entwurf.eaddraftLocalrefnr)
        this.currentLoading = false;
      })
    },

    setLkwKennzeichen () {
      this.currentLoading = true;
      const request = new NeadsubKennzeichenRequest(this.entwurf.id, this.lkwKennzeichenEdit.trim())
      this.store.dispatch(ActionTypes.SetKennzeichenNEadSub, request)
      .then(() => {
        this.currentLoading = false;
        if (this.neadsubErrorMsg == "") {
          this.closeLkwKennzeichenEditMode();
        }
      })
    },

    downloadAndShowComponent () {
      if (this.authUser != null) {
        this.showModal()
        this.comploaded = false
        // this.$emit("toggleTimer", false)

        const data: GetEVDDocCompRequest = {
          eaddraftLocalrefnr: this.entwurf.eaddraftLocalrefnr,
          archivtoken: this.authUser.token
        }

        this.store.dispatch(ActionTypes.GetEVDDocComp, data)
        .then(() => {
          this.pdfUrl = this.base64ToBlobUrl(this.componente.file.filecontentAsBase64)
          this.comploaded = true
          // this.$emit("toggleTimer", true)
        })
      }
    },

    closePdfModal () {
      this.closeModal();

      // this.store.dispatch(ArchivActionTypes.ArchivClearComponentenCache, undefined);
    },

    drucken () {
      if (this.authUser != null) {
        this.currentLoading = true;
        this.$emit("toggleTimer", false)

        const data: GetEVDDocCompRequest = {
          eaddraftLocalrefnr: this.entwurf.eaddraftLocalrefnr,
          archivtoken: this.authUser.token
        }

        this.store.dispatch(ActionTypes.GetEVDDocComp, data)
        .then(() => {
          var pdfUrl = this.base64ToBlobUrl(this.componente.file.filecontentAsBase64)
          this.printDoc(pdfUrl)
          this.$emit("toggleTimer", true)
          this.currentLoading = false;

          // var data = new ArchivDownloadCompRequest(this.componente.archivmandant, this.componente.archivname, this.componente.docid, this.componente.compid)
          // this.store.dispatch(ActionTypes.DownloadEvdDocComp, data)
          // .then(() => {
          //   var pdfUrl = this.base64ToBlobUrl(this.componente.file.filecontentAsBase64)
          //   this.printDoc(pdfUrl)
          //   this.$emit("toggleTimer", true)
          //   this.currentLoading = false;
          // })
        })
      }
    },

    async printDoc(blobUrl: string) {
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = blobUrl

      const viewer = document.getElementById('bezugsnummer-' + this.entwurf.eaddraftLocalrefnr) as HTMLDivElement;
      viewer.appendChild(iframe)
      
      iframe.onload = () => {
        iframe.contentWindow?.print()
      }
    },

    base64ToBlobUrl(base64: string) {
      const binStr = atob( base64 );
      const len = binStr.length;
      const arr = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        arr[ i ] = binStr.charCodeAt( i );
      }
      const blob =  new Blob( [ arr ], { type: 'application/pdf' } );
      const url = URL.createObjectURL( blob );
      return url;
    },

    showSendEmailModal() {
      console.log("not implemented yet.");
      // this.showError = false
      // this.showEmailModal()
    },

    closeSendEmailModal() {
      console.log("not implemented yet.");
      // this.privateEmail = this.personaldaten.email
      // this.closeEmailModal()
    },

    sendmail () {
      console.log("not implemented yet.");
      // const data: SendDocAsMailRequest = {
      //   archivmandant: this.personaldaten.archivmandant,
      //   archivname: this.personaldaten.archivname,
      //   docguid: this.dokument.componente.docid.trim(),
      //   compguid: this.dokument.componente.compid.trim(),
      //   filename: this.dokument.componente.filename.trim(),
      //   archivtoken: this.archivUser.token,
      //   responseEmailAdresse: this.privateEmail
      // };

      // this.store.dispatch(PersonalActionTypes.SendDocAsMail, data)
      //   .then(() => {
      //     if (this.emailErrorMsg == null || this.emailErrorMsg == "") {
      //       this.closeEmailModal()
      //     }
      //     else {
      //       this.showError = true
      //     }
      //   });
    }
  },

  mounted () {
    this.pdfViewerOptions.defaultScale = PDFVIEWERDEFAULTSCALE
    this.pdfViewerOptions.showEmailSendenOption = false
  }
})
</script>

<style lang="scss" scoped>
/* .entwurf:hover {
  color: #d99728;
  cursor: pointer;
} */
</style>