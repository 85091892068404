import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Home from '../views/Home.vue'
import Versender from '@/views/Versender.vue'
import Empfaenger from '@/views/Empfaenger.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/versender',
    name: 'Versender',
    component: Versender
  },
  {
    path: '/empfaenger',
    name: 'Empfaenger',
    component: Empfaenger
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

import { useStore } from '@/store'

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/login', '/logout'];
  const authRequired = !publicPages.includes(to.path);
  const store = useStore();
  // const personalLoggedIn = store.getters.status.personalLoggedIn

  // let loggedIn = false;

  // if ((localStorage.getItem('user') || sessionStorage.getItem('user')) && personalLoggedIn) {
  //   loggedIn = true
  // }

  let loggedIn = false;

  if ((localStorage.getItem('authUser') || sessionStorage.getItem('authUser'))) {
    loggedIn = true
  }

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next();
});

export default router
