import { createApp } from 'vue'

import App from './App.vue'
// import Emitter from 'tiny-emitter';
import router from './router'
import {store} from './store'
// import './registerServiceWorker'

import { library } from "@fortawesome/fontawesome-svg-core";

import { faWindows } from '@fortawesome/free-brands-svg-icons'

import {
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBars,
  faDesktop,
  faEnvelope,
  faFileContract,
  faFileDownload,
  faFileExport,
  faFileImport,
  faFloppyDisk,
  faHeadset,
  faMagnifyingGlassPlus,
  faMinus,
  faPaperPlane,
  faPen,
  faPlus,
  faPrint,
  faRectangleList,
  faRotate,
  faServer,
  faShieldHalved,
  faXmark
} from "@fortawesome/free-solid-svg-icons";



library.add(
  faArrowRightFromBracket,
  faArrowRightToBracket,
  faBars,
  faDesktop,
  faEnvelope,
  faFileContract,
  faFileDownload,
  faFileExport,
  faFileImport,
  faFloppyDisk,
  faHeadset,
  faMagnifyingGlassPlus,
  faMinus,
  faPaperPlane,
  faPen,
  faPlus,
  faPrint,
  faRectangleList,
  faRotate,
  faServer,
  faShieldHalved,
  faWindows,
  faXmark
);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App);
// app.config.globalProperties.$msalInstance = {};
// app.config.globalProperties.$emitter = new Emitter();

app.use(store)
app.use(router)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')

import "bootstrap"