import { RootState } from '@/store';

import { GetterTree } from "vuex"
import { State } from "./state"
import { CEadVal, CEadValPos, Entwurf, NEadSubPos } from "@/models/Zelos/ZelosModels"
import { ArchivComponente } from '@/models/Archiv/ArchivModels';


export type Getters = {
  entwurfe(state: State): Array<Entwurf>,
  archivComponente(state: State): ArchivComponente,
  neadsubpos(state: State): Array<NEadSubPos>,
  ceadval(state: State): Array<CEadVal>,
  ceadvalpos(state: State): Array<CEadValPos>,
}

export const getters: GetterTree<State, RootState> & Getters = {
  entwurfe(state) {
    return state.entwurfe;
  },

  archivComponente(state) {
    return state.archivComponente;
  },

  neadsubpos(state) {
    return state.neadsubpos
  },

  ceadval(state: State) {
    return state.ceadval;
  },
  
  ceadvalpos(state: State) {
    return state.ceadvalpos;
  },
}