import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './Interceptors';

import {SendEVDRequest, ExecuteExportRequest, GetEVDDocCompRequest, NeadsubKennzeichenRequest} from "@/models/Zelos/ZelosModels"
import { RefreshLoginRequest, LogoutRequest } from '../models/Archiv/ArchivModels';

/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json()

const BASE_URL = config.zelosapi.apiUrl;
const API_KEY = config.zelosapi.apiKey;

const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));



export default {
  //#region  Auth
  loginZelos(): Promise<AxiosResponse> {
    return apiClient.get("/login")
  },
  
  refreshLoginZelos(data: RefreshLoginRequest) {
    return apiClient.post("/login/refresh", data)
  },
  
  logoutZelos(data: LogoutRequest): Promise<AxiosResponse> {
    return apiClient.post('/logout', data);
  },
  //#endregion

  //#region NEadSub
  getAllEntwurfeDatum(datum: string): Promise<AxiosResponse> {
    return apiClient.get(`/neadsub?datum=${datum}`);
  },

  getNeadsubByEaddraftlocalrefnr(eaddraftlocalrefnr: string): Promise<AxiosResponse> {
    return apiClient.get(`/neadsub/eaddraftlocalrefnr/${eaddraftlocalrefnr}`);
  },

  getEVDDocComp(data: GetEVDDocCompRequest): Promise<AxiosResponse> {
    return apiClient.get(`/neadsub/doc?eaddraftLocalrefnr=${data.eaddraftLocalrefnr}`, {
      headers: {
        "ArchivToken": data.archivtoken
      }
    })
  },
  
  sendBezugsnummer(data: SendEVDRequest): Promise<AxiosResponse> {
    return apiClient.get(`/neadsub/eaddraftlocalrefnr?headermessagesender=${data.headermessagesender}&eaddraftLocalrefnr=${data.eaddraftLocalrefnr}`);
  },

  executeExport(data: ExecuteExportRequest): Promise<AxiosResponse> {
    return apiClient.post('/export', data);
  },

  neadsubEditKennzeichen(data: NeadsubKennzeichenRequest): Promise<AxiosResponse> {
    return apiClient.put("/neadsub/kennzeichen", data)
  },
  //#endregion

  //#region NEadSubPos
  getNEadSubPosByNEadSubId(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/neadsubpos/neadsubid/${id}`)
  },
  //#endregion

  //#region CEadVal
  getCEadValDatum(datum: string): Promise<AxiosResponse> {
    return apiClient.get(`/ceadval?datum=${datum}`)
  },
  //#endregion

  //#region  CEAdValPos
  getCEadValPosByCEadValId(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/ceadvalpos/ceadvalid/${id}`)
  }
  //#endregion
}