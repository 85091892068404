export interface SendEVDRequest {
  headermessagesender: string;
  eaddraftLocalrefnr: string
}

export class ExecuteExportRequest {
  headermessagesender: string;
  bezugsnummer: string;
  vorgang: string;

  constructor (headermessagesender: string, bezugsnummer: string, vorgang: string) {
    this.headermessagesender = headermessagesender;
    this.bezugsnummer = bezugsnummer;
    this.vorgang = vorgang;
  }
}

export class Entwurf {
  id: number
  adate: Date
  mdate: Date
  mitarbeiter: string
  status: number
  archivDocguid: string
  headerMessagesender: string
  headerMessagerecipient: string
  headerDateofpreparation: Date
  headerTimeofpreparation: Date
  headerInterchangecontrolref: string
  headerScenarionr: number
  headerScenarionote: string
  headerMessagegroup: string
  headerTestindicator: number
  headerMessageidentifier: string
  headerMessageversion: string
  headerBin: string
  attributesDeferredsubmission: number
  attributesSubmissionmsgtype: number
  dispatchimportofficeRefnr: string
  deliveryplacecustomofficeRefnr: string
  competauthdispatchofficeRefnr: string
  headereadJourneytime: string
  headereadDestinationtypecode: number
  headereadTransportarrangement: number
  eaddraftDateofdispatch: Date
  eaddraftTimeofdispatch: Date
  eaddraftOrigintypecode: number
  eaddraftLocalrefnr: string
  eaddraftInvoicenr: string
  eaddraftInvoicedate: Date
  eaddraftImportsadnr: string
  moveguaranteeGuarantortypecode: number
  guarantortraderNadlng: string
  guarantortraderTraderexcisenr: string
  guarantortraderVat: string
  guarantortraderCity: string
  guarantortraderStreet: string
  guarantortraderStreetnr: string
  guarantortraderPostcode: string
  guarantortraderTrader: string
  transportmodeModecode: number
  transportmodeComplinfo: string
  transportmodeComplinfolng: string
  doccertificateDocref: string
  doccertificateDoctype: string
  doccertificateDocdescrip: string
  doccertificateDocdescriplng: string
  doccertificateRefofdoc: string
  doccertificateRefofdoclng: string
  consigneetraderNadlng: string
  consigneetraderTraderid: string
  consigneetraderCity: string
  consigneetraderStreet: string
  consigneetraderStreetnr: string
  consigneetraderrPostcode: string
  consigneetraderEorinr: string
  consigneetraderTrader: string
  consignortraderNadlng: string
  consignortraderTraderexcisenr: string
  consignortraderCity: string
  consignortraderStreet: string
  consignortraderStreetnr: string
  consignortraderPostcode: string
  consignortraderTrader: string
  placeofdispatchtraderNadlng: string
  placeofdispatchtraderReftax: string
  placeofdispatchtraderCity: string
  placeofdispatchtraderStreet: string
  placeofdispatchtraderStreetnr: string
  placeofdispatchtraderPostcode: string
  placeofdispatchtraderTrader: string
  complconsigneetraderStatecode: string
  complconsigneetraderCertexempt: string
  deliveryplacetraderNadlng: string
  deliveryplacetraderTraderid: string
  deliveryplacetraderCity: string
  deliveryplacetraderStreet: string
  deliveryplacetraderStreetnr: string
  deliveryplacetraderPostcode: string
  deliveryplacetraderTrader: string
  transportarrangtraderNadlng: string
  transportarrangtraderVat: string
  transportarrangtraderCity: string
  transportarrangtraderStreet: string
  transportarrangtraderStreetnr: string
  transportarrangtraderPostcode: string
  transportarrangtraderTrader: string
  firsttransportertraderNadlng: string
  firsttransportertraderVat: string
  firsttransportertraderCity: string
  firsttransportertraderStreet: string
  firsttransportertraderStreetnr: string
  firsttransportertraderPostcode: string
  firsttransportertraderTrader: string
  transportdetailsIdoftransunit: string
  transportdetailsCommercsealid: string
  transportdetailsComplinfo: string
  transportdetailsComplinfolng: string
  transportdetailsSealinfo: string
  transportdetailsSealinfolng: string
  transportdetailsUnitcode: number
  versenderid: number
  empfaengerid: number
  transportid: number
  lastmessagetype: string
  lastmessagetimestamp: Date | null


  constructor() {
    this.id = 0;
    this.adate =  new Date();
    this.mdate =  new Date();
    this.mitarbeiter = "";
    this.status = 0;
    this.archivDocguid = "";
    this.headerMessagesender = "";
    this.headerMessagerecipient = "";
    this.headerDateofpreparation =  new Date();
    this.headerTimeofpreparation =  new Date();
    this.headerInterchangecontrolref = "";
    this.headerScenarionr = 0
    this.headerScenarionote = "";
    this.headerMessagegroup = "";
    this.headerTestindicator = 0;
    this.headerMessageidentifier = "";
    this.headerMessageversion = "";
    this.headerBin = "";
    this.attributesDeferredsubmission = 0;
    this.attributesSubmissionmsgtype = 0;
    this.dispatchimportofficeRefnr = "";
    this.deliveryplacecustomofficeRefnr = "";
    this.competauthdispatchofficeRefnr = "";
    this.headereadJourneytime = "";
    this.headereadDestinationtypecode = 0;
    this.headereadTransportarrangement = 0;
    this.eaddraftDateofdispatch =  new Date();
    this.eaddraftTimeofdispatch =  new Date();
    this.eaddraftOrigintypecode = 0;
    this.eaddraftLocalrefnr = "";
    this.eaddraftInvoicenr = "";
    this.eaddraftInvoicedate =  new Date();
    this.eaddraftImportsadnr = "";
    this.moveguaranteeGuarantortypecode = 0;
    this.guarantortraderNadlng = "";
    this.guarantortraderTraderexcisenr = "";
    this.guarantortraderVat = "";
    this.guarantortraderCity = "";
    this.guarantortraderStreet = "";
    this.guarantortraderStreetnr = "";
    this.guarantortraderPostcode = "";
    this.guarantortraderTrader = "";
    this.transportmodeModecode = 0;
    this.transportmodeComplinfo = "";
    this.transportmodeComplinfolng = "";
    this.doccertificateDocref = "";
    this.doccertificateDoctype = "";
    this.doccertificateDocdescrip = "";
    this.doccertificateDocdescriplng = "";
    this.doccertificateRefofdoc = "";
    this.doccertificateRefofdoclng = "";
    this.consigneetraderNadlng = "";
    this.consigneetraderTraderid = "";
    this.consigneetraderCity = "";
    this.consigneetraderStreet = "";
    this.consigneetraderStreetnr = "";
    this.consigneetraderrPostcode = "";
    this.consigneetraderEorinr = "";
    this.consigneetraderTrader = "";
    this.consignortraderNadlng = "";
    this.consignortraderTraderexcisenr = "";
    this.consignortraderCity = "";
    this.consignortraderStreet = "";
    this.consignortraderStreetnr = "";
    this.consignortraderPostcode = "";
    this.consignortraderTrader = "";
    this.placeofdispatchtraderNadlng = "";
    this.placeofdispatchtraderReftax = "";
    this.placeofdispatchtraderCity = "";
    this.placeofdispatchtraderStreet = "";
    this.placeofdispatchtraderStreetnr = "";
    this.placeofdispatchtraderPostcode = "";
    this.placeofdispatchtraderTrader = "";
    this.complconsigneetraderStatecode = "";
    this.complconsigneetraderCertexempt = "";
    this.deliveryplacetraderNadlng = "";
    this.deliveryplacetraderTraderid = "";
    this.deliveryplacetraderCity = "";
    this.deliveryplacetraderStreet = "";
    this.deliveryplacetraderStreetnr = "";
    this.deliveryplacetraderPostcode = "";
    this.deliveryplacetraderTrader = "";
    this.transportarrangtraderNadlng = "";
    this.transportarrangtraderVat = "";
    this.transportarrangtraderCity = "";
    this.transportarrangtraderStreet = "";
    this.transportarrangtraderStreetnr = "";
    this.transportarrangtraderPostcode = "";
    this.transportarrangtraderTrader = "";
    this.firsttransportertraderNadlng = "";
    this.firsttransportertraderVat = "";
    this.firsttransportertraderCity = "";
    this.firsttransportertraderStreet = "";
    this.firsttransportertraderStreetnr = "";
    this.firsttransportertraderPostcode = "";
    this.firsttransportertraderTrader = "";
    this.transportdetailsIdoftransunit = "";
    this.transportdetailsCommercsealid = "";
    this.transportdetailsComplinfo = "";
    this.transportdetailsComplinfolng = "";
    this.transportdetailsSealinfo = "";
    this.transportdetailsSealinfolng = "";
    this.transportdetailsUnitcode = 0;
    this.versenderid = 0;
    this.empfaengerid = 0;
    this.transportid = 0;
    this.lastmessagetype = "";
    this.lastmessagetimestamp = null;
  }
}

export class NeadsubKennzeichenRequest {
  id: number;
  kennzeichen: string;

  constructor(id: number, kennzeichen: string) {
    this.id = id;
    this.kennzeichen = kennzeichen;
  }
}

export interface GetEVDDocCompRequest {
  eaddraftLocalrefnr: string
  archivtoken: string
}

export class NEadSubPos {
  id: number;
  nEadSubId: number;
  eadbodyBodyrecorduniqueref: number;
  eadbodyExciseproductcode: string;
  eadbodyCncode: number;
  eadbodyFiscalmarkusedflag: number;
  eadbodyFiscalmark: string;
  eadbodyFiscalmarklng: string;
  eadbodyDesignationoforigin: string;
  eadbodyDesignationoforiginlng: string;
  eadbodyCommercialdescrip: string;
  eadbodyCommercialdescriplng: string;
  eadbodyBrandnameofproducts: string;
  eadbodyBrandnameofproductslng: string;
  eadbodyQuantity: number;
  eadbodyGrossmass: number;
  eadbodyNetmass: number;
  eadbodyAlcoholicstrength: number;
  eadbodyDegreeplato: number;
  eadbodySizeofproducer: number;
  eadbodyDensity: number;
  eadbodyAgeofproducts: string;
  eadbodyAgeofproductslng: string;
  packageKindofpackages: string;
  packageNumberofpackages: number;
  packageCommercialsealid: string;
  packageShippingmarks: string;
  packageSealinformation: string;
  packageSealinformationlng: string;
  wineproductWinegrowingzonecode: number;
  wineproductWineprodcategory: number;
  wineproductThirdcountryorigin: string;
  wineproductOtherinfo: string;
  wineproductOtherinfolng: string;
  wineproductWineoperationcode: number;

  constructor () {
    this.id = 0;
    this.nEadSubId = 0;
    this.eadbodyBodyrecorduniqueref = 0;
    this.eadbodyExciseproductcode = "";
    this.eadbodyCncode = 0;
    this.eadbodyFiscalmarkusedflag = 0;
    this.eadbodyFiscalmark = "";
    this.eadbodyFiscalmarklng = "";
    this.eadbodyDesignationoforigin = "";
    this.eadbodyDesignationoforiginlng = "";
    this.eadbodyCommercialdescrip = "";
    this.eadbodyCommercialdescriplng = "";
    this.eadbodyBrandnameofproducts = "";
    this.eadbodyBrandnameofproductslng = "";
    this.eadbodyQuantity = 0;
    this.eadbodyGrossmass = 0;
    this.eadbodyNetmass = 0;
    this.eadbodyAlcoholicstrength = 0;
    this.eadbodyDegreeplato = 0;
    this.eadbodySizeofproducer = 0;
    this.eadbodyDensity = 0;
    this.eadbodyAgeofproducts = "";
    this.eadbodyAgeofproductslng = "";
    this.packageKindofpackages = "";
    this.packageNumberofpackages = 0;
    this.packageCommercialsealid = "";
    this.packageShippingmarks = "";
    this.packageSealinformation = "";
    this.packageSealinformationlng = "";
    this.wineproductWinegrowingzonecode = 0;
    this.wineproductWineprodcategory = 0;
    this.wineproductThirdcountryorigin = "";
    this.wineproductOtherinfo = "";
    this.wineproductOtherinfolng = "";
    this.wineproductWineoperationcode = 0;
  }
}

export class CEadVal {
  id: number;
  adate: Date | null;
  mdate: Date | null;
  mitarbeiter: string;
  nEadSubId: number;
  archivDocguid: string;
  headerMessagesender: string;
  headerMessagerecipient: string;
  headerDateofpreparation: Date | null;
  headerTimeofpreparation: Date | null;
  headerInterchangecontrolref: string;
  headerScenarionr: number;
  headerMessagegroup: string;
  headerTestindicator: number;
  headerMessageidentifier: string;
  headerMessageversion: string;
  excisemoveArc: string;
  excisemoveDatetimevalidation: Date | null;
  dispatchimportofficeRefnr: string;
  deliveryplacecustomofficeRefnr: string;
  competauthdispatchofficeRefnr: string;
  headereadDatetimevalidation: Date | null;
  headereadJourneytime: string;
  headereadSequencenr: number;
  headereadDestinationtypecode: number;
  headereadTransportarrangement: number;
  eadesadDateofdispatch: Date | null;
  eadesadTimeofdispatch: Date | null;
  eadesadOrigintypecode: number;
  eadesadLocalrefnr: string;
  eadesadUpstreamarc: string;
  eadesadInvoicenr: string;
  eadesadInvoicedate: Date | null;
  eadesadImportsadnr: string;
  moveguaranteeGuarantortypecode: number;
  guarantortraderNadlng: string;
  guarantortraderTraderexcisenr: string;
  guarantortraderVat: string;
  guarantortraderCity: string;
  guarantortraderStreet: string;
  guarantortraderStreetnr: string;
  guarantortraderPostcode: string;
  guarantortraderTrader: string;
  transportmodeModecode: number;
  transportmodeComplinfo: string;
  transportmodeComplinfolng: string;
  doccertificateDocref: string;
  doccertificateDoctype: string;
  doccertificateDocdescrip: string;
  doccertificateDocdescriplng: string;
  doccertificateRefofdoc: string;
  doccertificateRefofdoclng: string;
  consigneetraderNadlng: string;
  consigneetraderTraderid: string;
  consigneetraderCity: string;
  consigneetraderStreet: string;
  consigneetraderStreetnr: string;
  consigneetraderrPostcode: string;
  consigneetraderEorinr: string;
  consigneetraderTrader: string;
  consignortraderNadlng: string;
  consignortraderTraderexcisenr: string;
  consignortraderCity: string;
  consignortraderStreet: string;
  consignortraderStreetnr: string;
  consignortraderPostcode: string;
  consignortraderTrader: string;
  placeofdispatchtraderNadlng: string;
  placeofdispatchtraderReftax: string;
  placeofdispatchtraderCity: string;
  placeofdispatchtraderStreet: string;
  placeofdispatchtraderStreetnr: string;
  placeofdispatchtraderPostcode: string;
  placeofdispatchtraderTrader: string;
  complconsigneetraderStatecode: string;
  complconsigneetraderCertexempt: string;
  deliveryplacetraderNadlng: string;
  deliveryplacetraderTraderid: string;
  deliveryplacetraderCity: string;
  deliveryplacetraderStreet: string;
  deliveryplacetraderStreetnr: string;
  deliveryplacetraderPostcode: string;
  deliveryplacetraderTrader: string;
  transportarrangtraderNadlng: string;
  transportarrangtraderVat: string;
  transportarrangtraderCity: string;
  transportarrangtraderStreet: string;
  transportarrangtraderStreetnr: string;
  transportarrangtraderPostcode: string;
  transportarrangtraderTrader: string;
  firsttransportertraderNadlng: string;
  firsttransportertraderVat: string;
  firsttransportertraderCity: string;
  firsttransportertraderStreet: string;
  firsttransportertraderStreetnr: string;
  firsttransportertraderPostcode: string;
  firsttransportertraderTrader: string;
  transportdetailsUnitcode: number;
  transportdetailsIdoftransunit: string;
  transportdetailsCommercsealid: string;
  transportdetailsComplinfo: string;
  transportdetailsComplinfolng: string;
  transportdetailsSealinfo: string;
  transportdetailsSealinfolng: string;
  status: number;
  lastmessagetype: string;
  lastmessagetimestamp: Date | null;

  constructor () {
    this.id = 0;
    this.adate = null;
    this.mdate = null;
    this.mitarbeiter = "";
    this.nEadSubId = 0;
    this.archivDocguid = "";
    this.headerMessagesender = "";
    this.headerMessagerecipient = "";
    this.headerDateofpreparation = null;
    this.headerTimeofpreparation = null;
    this.headerInterchangecontrolref = "";
    this.headerScenarionr = 0;
    this.headerMessagegroup = "";
    this.headerTestindicator = 0;
    this.headerMessageidentifier = "";
    this.headerMessageversion = "";
    this.excisemoveArc = "";
    this.excisemoveDatetimevalidation = null;
    this.dispatchimportofficeRefnr = "";
    this.deliveryplacecustomofficeRefnr = "";
    this.competauthdispatchofficeRefnr = "";
    this.headereadDatetimevalidation = null;
    this.headereadJourneytime = "";
    this.headereadSequencenr = 0;
    this.headereadDestinationtypecode = 0;
    this.headereadTransportarrangement = 0;
    this.eadesadDateofdispatch = null;
    this.eadesadTimeofdispatch = null;
    this.eadesadOrigintypecode = 0;
    this.eadesadLocalrefnr = "";
    this.eadesadUpstreamarc = "";
    this.eadesadInvoicenr = "";
    this.eadesadInvoicedate = null;
    this.eadesadImportsadnr = "";
    this.moveguaranteeGuarantortypecode = 0;
    this.guarantortraderNadlng = "";
    this.guarantortraderTraderexcisenr = "";
    this.guarantortraderVat = "";
    this.guarantortraderCity = "";
    this.guarantortraderStreet = "";
    this.guarantortraderStreetnr = "";
    this.guarantortraderPostcode = "";
    this.guarantortraderTrader = "";
    this.transportmodeModecode = 0;
    this.transportmodeComplinfo = "";
    this.transportmodeComplinfolng = "";
    this.doccertificateDocref = "";
    this.doccertificateDoctype = "";
    this.doccertificateDocdescrip = "";
    this.doccertificateDocdescriplng = "";
    this.doccertificateRefofdoc = "";
    this.doccertificateRefofdoclng = "";
    this.consigneetraderNadlng = "";
    this.consigneetraderTraderid = "";
    this.consigneetraderCity = "";
    this.consigneetraderStreet = "";
    this.consigneetraderStreetnr = "";
    this.consigneetraderrPostcode = "";
    this.consigneetraderEorinr = "";
    this.consigneetraderTrader = "";
    this.consignortraderNadlng = "";
    this.consignortraderTraderexcisenr = "";
    this.consignortraderCity = "";
    this.consignortraderStreet = "";
    this.consignortraderStreetnr = "";
    this.consignortraderPostcode = "";
    this.consignortraderTrader = "";
    this.placeofdispatchtraderNadlng = "";
    this.placeofdispatchtraderReftax = "";
    this.placeofdispatchtraderCity = "";
    this.placeofdispatchtraderStreet = "";
    this.placeofdispatchtraderStreetnr = "";
    this.placeofdispatchtraderPostcode = "";
    this.placeofdispatchtraderTrader = "";
    this.complconsigneetraderStatecode = "";
    this.complconsigneetraderCertexempt = "";
    this.deliveryplacetraderNadlng = "";
    this.deliveryplacetraderTraderid = "";
    this.deliveryplacetraderCity = "";
    this.deliveryplacetraderStreet = "";
    this.deliveryplacetraderStreetnr = "";
    this.deliveryplacetraderPostcode = "";
    this.deliveryplacetraderTrader = "";
    this.transportarrangtraderNadlng = "";
    this.transportarrangtraderVat = "";
    this.transportarrangtraderCity = "";
    this.transportarrangtraderStreet = "";
    this.transportarrangtraderStreetnr = "";
    this.transportarrangtraderPostcode = "";
    this.transportarrangtraderTrader = "";
    this.firsttransportertraderNadlng = "";
    this.firsttransportertraderVat = "";
    this.firsttransportertraderCity = "";
    this.firsttransportertraderStreet = "";
    this.firsttransportertraderStreetnr = "";
    this.firsttransportertraderPostcode = "";
    this.firsttransportertraderTrader = "";
    this.transportdetailsUnitcode = 0;
    this.transportdetailsIdoftransunit = "";
    this.transportdetailsCommercsealid = "";
    this.transportdetailsComplinfo = "";
    this.transportdetailsComplinfolng = "";
    this.transportdetailsSealinfo = "";
    this.transportdetailsSealinfolng = "";
    this.status = 0;
    this.lastmessagetype = "";
    this.lastmessagetimestamp = null;
  }
}

export class CEadValPos {
  id: number;
  cEadValId: number;
  eadbodyBodyrecorduniqueref: number;
  eadbodyExciseproductcode: string;
  eadbodyCncode: number;
  eadbodyFiscalmarkusedflag: number;
  eadbodyFiscalmark: string;
  eadbodyFiscalmarklng: string;
  eadbodyDesignationoforigin: string;
  eadbodyDesignationoforiginlng: string;
  eadbodyCommercialdescrip: string;
  eadbodyCommercialdescriplng: string;
  eadbodyBrandnameofproducts: string;
  eadbodyBrandnameofproductslng: string;
  eadbodyQuantity: number;
  eadbodyGrossmass: number;
  eadbodyNetmass: number;
  eadbodyAlcoholicstrength: number;
  eadbodyDegreeplato: number;
  eadbodySizeofproducer: number;
  eadbodyDensity: number;
  eadbodyAgeofproducts: string;
  eadbodyAgeofproductslng: string;
  packageKindofpackages: string;
  packageNumberofpackages: number;
  packageCommercialsealid: string;
  packageShippingmarks: string;
  packageSealinformation: string;
  packageSealinformationlng: string;
  wineproductWinegrowingzonecode: number;
  wineproductWineprodcategory: number;
  wineproductThirdcountryorigin: string;
  wineproductOtherinfo: string;
  wineproductOtherinfolng: string;
  wineproductWineoperationcode: number;

  constructor() {
    this.id = 0;
    this.cEadValId = 0;
    this.eadbodyBodyrecorduniqueref = 0;
    this.eadbodyExciseproductcode = "";
    this.eadbodyCncode = 0;
    this.eadbodyFiscalmarkusedflag = 0;
    this.eadbodyFiscalmark = "";
    this.eadbodyFiscalmarklng = "";
    this.eadbodyDesignationoforigin = "";
    this.eadbodyDesignationoforiginlng = "";
    this.eadbodyCommercialdescrip = "";
    this.eadbodyCommercialdescriplng = "";
    this.eadbodyBrandnameofproducts = "";
    this.eadbodyBrandnameofproductslng = "";
    this.eadbodyQuantity = 0;
    this.eadbodyGrossmass = 0;
    this.eadbodyNetmass = 0;
    this.eadbodyAlcoholicstrength = 0;
    this.eadbodyDegreeplato = 0;
    this.eadbodySizeofproducer = 0;
    this.eadbodyDensity = 0;
    this.eadbodyAgeofproducts = "";
    this.eadbodyAgeofproductslng = "";
    this.packageKindofpackages = "";
    this.packageNumberofpackages = 0;
    this.packageCommercialsealid = "";
    this.packageShippingmarks = "";
    this.packageSealinformation = "";
    this.packageSealinformationlng = "";
    this.wineproductWinegrowingzonecode = 0;
    this.wineproductWineprodcategory = 0;
    this.wineproductThirdcountryorigin = "";
    this.wineproductOtherinfo = "";
    this.wineproductOtherinfolng = "";
    this.wineproductWineoperationcode = 0;
  }
}