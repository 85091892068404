<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-primary shadow-sm py-1 mb-5">
    <div class="container">

      <router-link :to="{ name: 'Home' }" class="navbar-brand">
        <img src="@/assets/icons/zelos.png" class="me-3" width="40">
        1.A Zelos
      </router-link>
      
      <button class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">

          <li class="nav-item my-auto ms-5">
            <router-link :to="{ name: 'Login' }" class="nav-link"><font-awesome-icon icon="arrow-right-to-bracket" class="me-2" /> Anmelden</router-link>
          </li>
        </ul>
      </div>

    </div>
  </nav>

  <div class="container">

    <div class="row mb-5">
      <div class="col-7 pe-5 my-auto">
        <h1>
          1.A-Zelos EMCS-Software
        </h1>
        <h2 class="my-3"><small class="text-body-secondary">für den reibungslosen Transport von verbrauchsteuerpflichten Gütern in der EU</small></h2>

        <p class="lead">Willkommen bei 1.A-Zelos, Ihrem zertifizierten Partner für EMCS (Exercise Movement and Control System) Software.</p>
        <p class="lead">Sie betreuen ein oder mehrere Steuerlager und möchten das manuelle Signieren über das EIA Portal automatisieren?  Für 149,00€ plus MwSt. pro Monat ohne aufwendige Installation direkt nutzbar.</p>
        <p class="lead">Fordern Sie hier Ihre Firmenkennung online an und starten Sie durch.</p>

        <strong class="fs-5 mt-3">Nur vier Schritte zur Digitalisierung mit 1.A-Zelos</strong>

        <ol class="lead">
          <li>Anforderung der Firmenkennung bei 1.A Connect GmbH</li>
          <li>Anmeldung des Unternehmens bei dem zuständigen Hauptzollamt mit Hinweis auf die gewünschte Nutzung der 1.A-Zelos Software</li>
          <li>Vergabe der Steuernummern durch das zuständige Hauptzollamt</li>
          <li>Einrichtung der Software mit den Steuernummern – los geht es.</li>
        </ol>

        <p class="lead">Wir möchten die 1.A-Zelos Software nutzen und fordern hiermit eine Firmenkennung an</p>

        <p>
          <a href="https://zelos.1a-register.de" class="btn btn-primary my-2 me-2">Firmenkennung anfordern</a>
        </p>
      </div>

      <div class="col-5">
        <div class="card">
          <div class="card-header bg-primary">
            <h5 class="py-0 my-0 text-white">Anmeldung</h5>
          </div>
          <div class="card-body">
            <p class="card-text">
              Geben Sie hier Ihre Firmenkennung ein:
            </p>

            <form @submit.prevent="goToLoginPage()">
              <div class="mb-3">
                <label for="firmenkennungInput" class="form-label">Firmenkennung</label>
                <input type="text" class="form-control" id="firmenkennungInput" v-model="firma" required>
              </div>

              <button type="submit" class="btn btn-primary"><font-awesome-icon icon="arrow-right-to-bracket" class="me-2" />Weiter zur Anmeldung</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center py-4 footer-bg">
      <a href="https://1aconnect.de/impressum" target="_blank" class="me-3">Impressum</a> |
      <a href="https://1aconnect.de/datenschutzerklaerung" target="_blank" class="ms-3">Datenschutz</a>
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Home",

  setup () {
    const route = useRoute();

    console.log(window.location.hostname);

    const firma = ref("")

    return {
      route,
      firma
    }
  },

  methods: {
    goToLoginPage() {
      window.open(`https://${this.firma.trim().toLowerCase()}.1a-zelos.de/login`, "_self")
    }
  }
})
</script>