import { RootState } from '@/store';
import { ActionContext, ActionTree } from "vuex";
import { Mutations, MutationTypes } from "./mutations";
import { State } from "./state";
import { CEadVal, CEadValPos, Entwurf, GetEVDDocCompRequest, NEadSubPos, SendEVDRequest, ExecuteExportRequest, NeadsubKennzeichenRequest} from "@/models/Zelos/ZelosModels"
import { ArchivComponente, ArchivDownloadCompRequest } from "@/models/Archiv/ArchivModels"

import { Actions as CommonActions, ActionTypes as CommonActionTypes} from "../Common/actions"

import ApiService from "@/services/ApiService";
import ArchivApiService from '@/services/ArchivApiService';


export enum ActionTypes {
  GetEntwurfe = "GETENTWURFE",
  GetNeadsubByEaddraftlocalrefnr = "GETNEADSUBBYEADDRAFTLOCALREFNR",
  GetUpdateEntwurfe = "GETUPDATEENTWURFE",
  SetKennzeichenNEadSub = "SETKENNZEICHENNEADSUB",
  SendBezugszunnumer = "SENDBEZUGSNUMMER",
  GetEVDDocComp = "GETEVDDOCCOMP",
  DownloadEvdDocComp = "DOWNLOADEVDDOCCOMP",
  GetNEadSubPosByNEadSubId = "GETNEADSUBPOSBYNEADSUBID",
  GetCEadValDatum = "GETCEADVALDATUM",
  GetUpdateCEadValDatum = "GETUPDATECEADVALDATUM",
  GetCEadValPosByCEadValId = "GETCEADVALPOSBYCEADVALID"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetEntwurfe](context: ActionArguments, datum: string): Promise<void>
  [ActionTypes.GetNeadsubByEaddraftlocalrefnr](context: ActionArguments, data: string): Promise<void>
  [ActionTypes.GetUpdateEntwurfe](context: ActionArguments, datum: string): Promise<void>
  [ActionTypes.SetKennzeichenNEadSub](context: ActionArguments, data: NeadsubKennzeichenRequest): Promise<void>
  [ActionTypes.SendBezugszunnumer](context: ActionArguments, data: ExecuteExportRequest): Promise<void>
  [ActionTypes.GetEVDDocComp](context: ActionArguments, data: GetEVDDocCompRequest): Promise<void>
  [ActionTypes.DownloadEvdDocComp](context: ActionArguments, data: ArchivDownloadCompRequest): Promise<void>
  [ActionTypes.GetNEadSubPosByNEadSubId](context: ActionArguments, id: number): Promise<void>
  [ActionTypes.GetCEadValDatum](context: ActionArguments, datum: string): Promise<void>
  [ActionTypes.GetUpdateCEadValDatum](context: ActionArguments, datum: string): Promise<void>
  [ActionTypes.GetCEadValPosByCEadValId](context: ActionArguments, id: number): Promise<void>
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetEntwurfe]({commit, dispatch, rootGetters}, datum) {
    const status = rootGetters.status;
    status.evdErrorMsg = "";
    status.evdLoading = true;
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetEntwurfe, new Array<Entwurf>())

    return ApiService.getAllEntwurfeDatum(datum)
      .then(res => {
        status.evdLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);

        const data: Array<Entwurf> = res.data
        commit(MutationTypes.SetEntwurfe, data)
      })
      .catch(error => {
        status.evdLoading = false;
        status.evdErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetUpdateEntwurfe]({commit, dispatch, rootGetters}, datum) {
    const status = rootGetters.status;

    return ApiService.getAllEntwurfeDatum(datum)
      .then(res => {
        const data: Array<Entwurf> = res.data
        commit(MutationTypes.SetEntwurfe, data)
      })
      .catch(error => {
        status.evdErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetNeadsubByEaddraftlocalrefnr]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateNeadsubLoading = true;
    status.neadsubErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getNeadsubByEaddraftlocalrefnr(data)
      .then(res => {
        const data: Entwurf = res.data
        dispatch(MutationTypes.UpdateNEadSub, data)
      })
      .catch(error => {
        status.neadsubErrorMsg = error.response;
      })
      .finally(() => {
        status.updateNeadsubLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },


  async [ActionTypes.SetKennzeichenNEadSub]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateNeadsubLoading = true;
    status.neadsubErrorMsg = "";
    
    return ApiService.neadsubEditKennzeichen(data)
    .then(res => {
      const data: Entwurf = res.data
      commit(MutationTypes.UpdateNEadSub, data)
    })
    .catch(error => {
      status.neadsubErrorMsg = error.response;
    })
    .finally(() => {
      status.updateNeadsubLoading = false;
      dispatch(CommonActionTypes.SetStatus, status);
    })
  },

  async [ActionTypes.SendBezugszunnumer]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.updateNeadsubLoading = true;
    status.neadsubErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.executeExport(data)
      .then(res => {
      })
      .catch(error => {
        status.neadsubErrorMsg = error.response;
      })
      .finally(() => {
        status.updateNeadsubLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetEVDDocComp]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;
    status.evdCompLoading = true;
    dispatch(CommonActionTypes.SetStatus, status);

    commit(MutationTypes.SetArchivComponente, new ArchivComponente());

    return ApiService.getEVDDocComp(data)
      .then(async (res) => {
        const data: ArchivComponente = res.data
        commit(MutationTypes.SetArchivComponente, data);
        
        var requestData = new ArchivDownloadCompRequest(data.archivmandant, data.archivname, data.docid, data.compid)
        await dispatch(ActionTypes.DownloadEvdDocComp, requestData)
      })
      .catch(async (error) => {
        console.log(error);
        status.evdErrorMsg = error.response;
      })
      .finally(() => {
        status.evdCompLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DownloadEvdDocComp]({commit, dispatch, rootGetters}, data) {
    const status = rootGetters.status;

    return ArchivApiService.getCompFile(data)
      .then(res => {
        let comp = rootGetters.archivComponente
        comp.file = res.data;
        commit(MutationTypes.SetArchivComponente, comp);

        // status.evdCompLoading = false;
        // dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetNEadSubPosByNEadSubId]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.getNeadsubposLoading = true;
    status.neadsubposErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);
    
    return ApiService.getNEadSubPosByNEadSubId(id)
    .then(res => {
      const data: Array<NEadSubPos> = res.data
      commit(MutationTypes.AddNEadSubPosRange, data)
    })
    .catch(error => {
      status.neadsubposErrorMsg = error.response;;
    })
    .finally(() => {
      status.getNeadsubposLoading = false;
      dispatch(CommonActionTypes.SetStatus, status);
    })
  },

  async [ActionTypes.GetCEadValDatum]({commit, dispatch, rootGetters}, datum) {
    const status = rootGetters.status;
    status.getCeadvalLoading = true;
    status.ceadvalErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getCEadValDatum(datum)
      .then(res => {
        const data: Array<CEadVal> = res.data
        commit(MutationTypes.SetCEadVal, data)
      })
      .catch(error => {
        status.evdErrorMsg = error.response;
      })
      .finally(() => {
        status.getCeadvalLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetUpdateCEadValDatum]({commit, dispatch, rootGetters}, datum) {
    const status = rootGetters.status;
    status.ceadvalErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.getCEadValDatum(datum)
      .then(res => {
        const data: Array<CEadVal> = res.data
        commit(MutationTypes.SetCEadVal, data)
      })
      .catch(error => {
        status.evdErrorMsg = error.response;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetCEadValPosByCEadValId]({commit, dispatch, rootGetters}, id) {
    const status = rootGetters.status;
    status.getCeadvalposLoading = true;
    status.ceadvalposErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);
    
    return ApiService.getCEadValPosByCEadValId(id)
    .then(res => {
      const data: Array<CEadValPos> = res.data
      commit(MutationTypes.AddCEadValPosRange, data)
    })
    .catch(error => {
      status.ceadvalposErrorMsg = error.response;;
    })
    .finally(() => {
      status.getCeadvalposLoading = false;
      dispatch(CommonActionTypes.SetStatus, status);
    })
  },
}