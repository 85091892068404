import { createStore, createLogger } from 'vuex';
// import createPersistedState from 'vuex-persistedstate';

import { store as auth, AuthStore, State as AuthState} from '@/store/modules/Auth';
import { store as common, CommonStore, State as CommonState} from '@/store/modules/Common';
import { store as zelos, ZelosStore, State as ZelosState} from '@/store/modules/Zelos';

export type RootState = {
  auth: AuthState;
  common: CommonState;
  zelos: ZelosState;
};

export type Store = AuthStore<Pick<RootState, "auth">>
  & CommonStore<Pick<RootState, 'common'>>
  & ZelosStore<Pick<RootState, 'zelos'>>

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

// plugins.push(createPersistedState({ storage: window.sessionStorage }));

export const store = createStore({
  plugins,
  modules: {
    auth: auth,
    common: common,
    zelos: zelos,
  },
});

export function useStore(): Store {
  return store as Store;
}