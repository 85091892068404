<template>
  <div class="container-fluid mt-2">
    <LoadingSpinner v-if="authLoading" :text="'...Sie werden abgemeldet...'"/>

    <div v-else>
      <div class="row mb-3">
        <div class="col-4 offset-4">

          <div class="input-group">
            <button class="btn btn-primary" @click="decreaseDate()">
              <font-awesome-icon icon="minus" />
            </button>
            <input type="date" class="form-control text-center fw-bold" v-model="dateConverter">
            <button class="btn btn-primary" @click="increaseDate()">
              <font-awesome-icon icon="plus" />
            </button>
          </div>
        </div>

        <div class="col-3 offset-1 text-end">
          <button class="btn my-auto" @click="updateData()">
            <font-awesome-icon icon="rotate" />
          </button>
        </div>
      </div>

      <div class="status-anzeige">Aktuallisiert um: {{ lastUpdatedConverter }}</div>

      <div class="row mb-2 mx-1 py-3 bg-secondary text-light shadow-sm">
        <div class="col-1 my-auto border-end border-2 fw-bold">Bezugsnummer</div>
        <div class="col-1 my-auto border-end border-2 fw-bold">Status</div>
        <div class="col-2 my-auto border-end border-2 fw-bold">Empfänger</div>
        <div class="col-2 my-auto border-end border-2 fw-bold">Empfängerlager</div>
        <div class="col-1 my-auto border-end border-2 fw-bold">Steuernummer</div>
        <div class="col-2 my-auto border-end border-2 fw-bold">Kennzeichen</div>
        <div class="col-1 my-auto border-end border-2 fw-bold">Nachricht</div>
        <div class="col-1 my-auto border-end border-2 fw-bold">Zeitstempel</div>
        <div class="col-1 my-auto"></div>
      </div>

      <LoadingSpinner v-if="loading" :text="loadingText"/>

      <div v-else>
        <div v-if="sortedentwurfe.length > 0">
          <Entwurf v-for="entwurf in sortedentwurfe" :key="entwurf.id" :entwurf="entwurf" @toggle-timer="toggleTimer" />
        </div>
        <div v-else>
          <div class="alert alert-info text-center w-50 mx-auto my-5">Es wurden keine Daten für das Datum gefunden</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'
import { ActionTypes } from "@/store/modules/Zelos/actions";

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Entwurf from "@/components/EVD/Entwurf.vue";

export default defineComponent({
  name: "Versender",

  components: {
    LoadingSpinner,
    Entwurf
  },


  setup () {
    const store = useStore();
    const authLoading = computed(() => store.getters.status.authLoading)
    const loading = computed(() => store.getters.status.evdLoading)
    const loadingText = ref("");

    const timer = ref();
    const lastUpdated = ref(new Date());
    const lastUpdatedConverter = computed({
      get () {
        // return lastUpdated.value.getFullYear() + "-" + ("0" + (lastUpdated.value.getMonth() + 1)).slice(-2) + "-" + ("0" + lastUpdated.value.getDate()).slice(-2);
        return lastUpdated.value.getHours() + ":" + ("0" + (lastUpdated.value.getMinutes() + 1)).slice(-2);
      },

      set (newValue) {
        lastUpdated.value = new Date(newValue)
      } 
    })

    const entwurfe = computed(() => store.getters.entwurfe)
    const sortedentwurfe = computed(() => {
      return entwurfe.value.sort((a,b) => {
        return a.eaddraftLocalrefnr < b.eaddraftLocalrefnr ? -1 : 1
      })
    })

    const date = ref(new Date());
    const dateConverter = computed({
      get () {
        return date.value.getFullYear() + "-" + ("0" + (date.value.getMonth() + 1)).slice(-2) + "-" + ("0" + date.value.getDate()).slice(-2);
      },

      set (newValue) {
        loadingText.value = "...Daten werden geladen..."

        if (newValue == "") {
          newValue = (new Date()).toISOString()
        }
        date.value = new Date(newValue);
        store.dispatch(ActionTypes.GetEntwurfe, date.value.toISOString())
      }
    })

    return {
      store,
      authLoading,
      loading,
      loadingText,
      lastUpdatedConverter,
      timer,
      sortedentwurfe,
      dateConverter,
      date
    }
  },

  methods: {
    increaseDate () {
      var date = new Date(this.date)
      date.setDate(date.getDate() + 1)
      this.dateConverter = date.toISOString()
    },

    decreaseDate () {
      var date = new Date(this.date)
      date.setDate(date.getDate() - 1)
      this.dateConverter = date.toISOString()
    },

    updateData () {
      this.loadingText = "...Daten werden geladen..."
      this.store.dispatch(ActionTypes.GetEntwurfe, this.date.toISOString())
      this.stopTimer();
      this.startTimer();
    },

    toggleTimer (start: boolean) {
      if (start) {
        this.startTimer();
      }
      else {
        this.stopTimer();
      }
    },

    startTimer () {
      this.timer = setInterval(() => {
        this.store.dispatch(ActionTypes.GetUpdateEntwurfe, this.date.toISOString())
        .then(() => {
          this.lastUpdatedConverter = (new Date()).toISOString()
        })

      }, 2* 60 * 1000)
    },

    stopTimer () {
      clearInterval(this.timer)
    }

  },

  mounted () {
    this.loadingText = "...Daten werden geladen..."
    this.store.dispatch(ActionTypes.GetEntwurfe, this.date.toISOString())

    this.startTimer();
  },

  beforeUnmount() {
    this.stopTimer();
  },
})
</script>

<style scoped>

.status-anzeige {
  position: relative;
  margin-top: -10px;
  margin-right: 5px;
  font-size: .8em;
  text-align: end;
}
</style>