<template>
  <!-- <nav id="sidebar" class="d-flex flex-column flex-shrink-0 p-3 bg-primary text-light shadow text-start"> -->
  <nav id="sidebar" class="bg-primary text-light shadow d-flex flex-column flex-shrink-0" :class="sidebarCollapsed ? 'sidebar-collapsed' : 'sidebar-notcollapsed p-3'">

    <!-- <img v-if="sidebarCollapsed" src="@/assets/icons/ew.png" class="mx-auto mt-3" width="30" @click="toggleSidebar()"> -->
    
    <router-link
      v-if="!sidebarCollapsed"
      :to="{ name: 'Versender' }"
      class="
        d-flex
        align-items-center
        mb-3 mb-md-0
        me-md-auto
        text-decoration-none
        text-light
      "
    >
      <!-- <font-awesome-icon icon="users-line" class="me-3 fa-2x" /> -->
      <img src="@/assets/icons/zelos.png" class="me-3" width="40">
      <span class="fs-4">1.A Zelos</span>
    </router-link>

    <hr v-if="!sidebarCollapsed">

    <ul v-if="!sidebarCollapsed" class="nav nav-pills flex-column mb-auto">
      <li class="nav-item mb-3" >
        <router-link :to="{ name: 'Versender' }" class="nav-link text-white w-100 d-flex px-1 rounded-0" active-class="active">
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="file-export" /></div><span class="flex-fill text-start my-auto">Versender</span>
        </router-link>
      </li>

      <li class="nav-item mb-3" >
        <router-link :to="{ name: 'Empfaenger' }" class="nav-link text-white w-100 d-flex px-1 rounded-0" active-class="active">
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="file-import" /></div><span class="flex-fill text-start my-auto">Empfänger</span>
        </router-link>
      </li>
    </ul>

    <hr v-if="!sidebarCollapsed">

    <a v-if="!sidebarCollapsed" href="https://1a-install.de/UpdateService.application" target="_blank" class="btn btn-outline-light w-100">Desktop App herunterladen</a>

  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'

export default defineComponent({

  setup () {
    const store = useStore();

    const user = computed(() => store.getters.zelosAuthUser)

    const sidebarCollapsed = computed(() => store.getters.status.sidebarCollapsed);

    return {
      store,
      user,
      sidebarCollapsed
    }
  },

});
</script>

<style lang="scss" scoped>
.sidebar-notcollapsed {
  width: 250px;
  transition: width .3s ease-in-out;
}
.sidebar-collapsed {
  width: 15px;
  transition: width .3s ease-in-out;
}

.active {
  background: #fff !important;
  color: #2A579B !important;
  // color: #fff !important;
}

.btn-toggle[aria-expanded="true"]::after {
    transform: rotate(0deg);
}

.btn-toggle::after {
    width: 1.25em;
    margin: auto 0 auto 0;
    line-height: 0;
    transition: transform .35s ease;
    transform: rotate(-90deg);
    content: url(../assets/icons/chevron-down-white.svg)
  }
</style>
  <!-- content: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e); -->