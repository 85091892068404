export class LoginRequest {
  username: string
  userpassword: string
  secureBrowserToken: string

  constructor(username: string, userpassword: string, secureBrowserToken: string) {
    this.username = username;
    this.userpassword = userpassword;
    this.secureBrowserToken = secureBrowserToken;
  }
}

// Login Response
export class AuthUser {
  userId: number
  benutzer: string
  username: string
  token: string
  refreshToken: string

  constructor () {
    this.userId = 0;
    this.benutzer = "";
    this.username = "";
    this.token = "";
    this.refreshToken = "";
  }
}

export interface AzureConfigEinstellung {
  clientId: string
  authority: string
  redirectUri: string
}

export class LoginAzureRequest {
  idToken: string
  username: string

  constructor (idToken: string, username: string) {
    this.idToken = idToken
    this.username = username
  }
}

export class ArchivDownloadCompRequest {
  archivname: string;
  archivmandant: string;
  docguid: string;
  compguid: string;

  constructor (archivmandant: string, archivname: string, docguid: string, compguid: string) {
    this.archivname = archivname,
    this.archivmandant = archivmandant,
    this.docguid = docguid,
    this.compguid = compguid
  }
}

export class ArchivComponenteFile {
  filesize: number;
  filecontentAsBase64: string;

  constructor() {
    this.filesize = 0,
    this.filecontentAsBase64 = ""
  }
}

export class ArchivComponente {
  id: number;
  // sortid: number;
  compid: string;
  docid: string;
  filename: string;
  // extvolname: string;
  // extfilename: string;
  contenttype: string;
  // charset: string;
  adate: Date;
  mdate: Date;
  // vdate: Date;
  // edate: Date;
  // ddate: Date;
  // alversion: string;
  // version: string;
  // barcode: string;
  titel: string;
  autor: string;
  // such_0: string;
  // such_1: string;
  // such_2: string;
  // such_3: string;
  // such_4: string;
  // such_5: string;
  // such_6: string;
  // such_7: string;
  // such_8: string;
  // such_9: string;
  // status: number;
  fileversion: number;
  // cryid: number;
  // keyid: number;
  file: ArchivComponenteFile;
  archivmandant: string;
  archivname: string;

  constructor() {
    this.id = 0,
    // this.sortid = 0,
    this.compid = "",
    this.docid = "",
    this.filename = "",
    // this.extvolname = "",
    // this.extfilename = "",
    this.contenttype = "",
    // this.charset = "",
    this.adate = new Date(),
    this.mdate = new Date(),
    // this.vdate = new Date(),
    // this.edate = new Date(),
    // this.ddate = new Date(),
    // this.alversion = "",
    // this.version = "",
    // this.barcode = "",
    this.titel = "",
    this.autor = "",
    // this.such_0 = "",
    // this.such_1 = "",
    // this.such_2 = "",
    // this.such_3 = "",
    // this.such_4 = "",
    // this.such_5 = "",
    // this.such_6 = "",
    // this.such_7 = "",
    // this.such_8 = "",
    // this.such_9 = "",
    // this.status = 0,
    this.fileversion = 0,
    // this.cryid = 0,
    // this.keyid = 0,
    this.file = new ArchivComponenteFile(),
    this.archivmandant = "",
    this.archivname = ""
  }
}

export class LogoutRequest {
  refreshtoken: string

  constructor (token: string) {
    this.refreshtoken = token
  }
}

export class RefreshLoginRequest {
  refreshtoken: string

  constructor (token: string) {
    this.refreshtoken = token
  }
}