import { AuthUser } from "@/models/Archiv/ArchivModels"
import { Configuration } from "@azure/msal-browser"

export type State = {
  authLoading: boolean
  authUser: AuthUser | null
  zelosAuthUser: AuthUser | null
  loggedIn: boolean
  authError: string

  azureLogin: boolean;
  azureLoginAvailable: boolean;
  azureUsername: string
  msalConfig: Configuration | null,
  identityToken: String
}

export const state: State = {
  authLoading: false,
  // loggedIn: localStorage.getItem("authUser") != null && localStorage.getItem("authUser") !== undefined ? true : false,
  loggedIn: false,
  authUser:  localStorage.getItem("authUser") != null && localStorage.getItem("authUser") !== undefined ? JSON.parse(localStorage.authUser) : null,
  zelosAuthUser:  localStorage.getItem("zelosAuthUser") != null ? JSON.parse(localStorage.zelosAuthUser) : null,
  authError: "",
  
  azureLogin: false,
  azureLoginAvailable: false,
  azureUsername: localStorage.getItem("azureusername") != null ? JSON.parse(localStorage.azureusername) : "",
  msalConfig: localStorage.getItem("msalconfig") != null ? JSON.parse(localStorage.msalconfig) : null,
  identityToken: ''
}
