import { Status } from "@/models/Common/CommonModels"

import { MutationTree } from "vuex"
import { State } from "./state"

export enum MutationTypes {
  SetStatus = "SETSTATUS",
}

export type Mutations = {
  [MutationTypes.SetStatus](state: State, status: Status): void
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.SetStatus](state, status) {
    state.status = status;
  },
}