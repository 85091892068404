import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "px-2 d-flex bg-secondary border-bottom border-primary" }
const _hoisted_3 = { class: "text-white" }
const _hoisted_4 = { class: "btn-group" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["disabled"]
const _hoisted_7 = { class: "text-white ms-2" }
const _hoisted_8 = {
  id: "outerContainer",
  class: "pb-2 bg-secondary h-100"
}
const _hoisted_9 = { id: "mainContainer" }
const _hoisted_10 = { class: "viewerContainer" }
const _hoisted_11 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    id: 'pdfViewerWrapper-' + _ctx.docId,
    class: "bg-secondary d-flex flex-column flex-grow h-100"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex flex-row align-items-center", !_ctx.options.showPagesOption ? 'd-none' : ''])
      }, [
        _createElementVNode("span", _hoisted_3, "Seiten: " + _toDisplayString(_ctx.numPages), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["d-flex flex-row align-items-center mx-auto", !_ctx.options.showScalingOption ? 'd-none' : ''])
      }, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("button", {
            class: "btn btn-secondary",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.zoomOut())),
            disabled: _ctx.scale <= 0.1
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "minus" })
          ], 8, _hoisted_5),
          _createElementVNode("button", {
            class: "btn btn-secondary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.zoomIn())),
            disabled: _ctx.scale >= 2
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "plus" })
          ], 8, _hoisted_6)
        ]),
        _createElementVNode("span", _hoisted_7, _toDisplayString(Math.floor(_ctx.scale * 100)) + "%", 1)
      ], 2),
      _createElementVNode("div", null, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-secondary", !_ctx.options.showPrintOption ? 'd-none' : '']),
          title: "Drucken",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.printPDF()))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "print",
            class: "p-1 fa-lg"
          })
        ], 2),
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-secondary", !_ctx.options.showDownloadOption ? 'd-none' : '']),
          title: "Herunterladen",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.saveInFileSystem()))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "file-download",
            class: "p-1 fa-lg"
          })
        ], 2),
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-secondary", !_ctx.options.showEmailSendenOption ? 'd-none' : '']),
          title: "An E-Mail senden",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.sendAsEmail()))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: "envelope",
            class: "p-1 fa-lg"
          })
        ], 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            id: 'viewer-' + _ctx.docId,
            class: "pdfViewer"
          }, null, 8, _hoisted_11)
        ])
      ])
    ])
  ], 8, _hoisted_1))
}