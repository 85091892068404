<template>
  <div :id="'bezugsnummer-' + ceadval.id" class="mb-2 mx-1 shadow-sm rounded-0 py-2" :class="statusBGClass">
    <div  class="row">
      <div class="col-1 my-auto">{{ ceadval.id }}</div>
      <div class="col-1 my-auto">{{ statusConverter }}</div>
      <div class="col-2 my-auto">{{ ceadval.eadesadLocalrefnr }}</div>
      <div class="col-1 my-auto">{{ ceadval.eadesadInvoicenr }}</div>
      <div class="col-1 my-auto">{{ invoicedateConverter }}</div>
      
      <div class="col-3 my-auto">{{ ceadval.consignortraderTrader }}</div>

      <div class="col-1 my-auto">{{ ceadval.lastmessagetype }}</div>
      <div class="col-1 my-auto">{{ zeitstempelConverter}}</div>

      <div class="col-1 my-auto text-center">
        <div v-if="ceadval.status == 0" class="btn-group">
          <button  class="btn btn-secondary" data-bs-toggle="collapse" :data-bs-target="'#details-' + ceadval.id" @click="getCEadValPos()">
            <font-awesome-icon icon="magnifying-glass-plus" />
          </button>

          <!-- <button  class="btn btn-primary">
            <font-awesome-icon icon="paper-plane" />
          </button> -->
        </div>

        <div v-else-if="ceadval.status == 2" class="btn-group">
          <button class="btn btn-primary">
            <font-awesome-icon icon="magnifying-glass-plus" data-bs-toggle="collapse" :data-bs-target="'#details-' + ceadval.id" @click="getCEadValPos()" />
          </button>

          <!-- <button class="btn btn-secondary" :disabled="evdCompLoading" @click="downloadAndShowComponent()">
            <div v-if="currentLoading" class="spinner-border spinner-border-sm text-light" role="status">
              <span class="sr-only"></span>
            </div>
            <font-awesome-icon v-else icon="print" />
          </button>
          -->

          <!-- <button class="btn btn-success" :disabled="evdCompLoading" @click="drucken()">
            <div v-if="currentLoading" class="spinner-border spinner-border-sm text-light" role="status">
              <span class="sr-only"></span>
            </div>
            <font-awesome-icon v-else icon="envelope" class="fa-lg" />
          </button> -->
        </div>

        <button v-else class="btn btn-secondary" data-bs-toggle="collapse" :data-bs-target="'#details-' + ceadval.id" @click="getCEadValPos()">
          <font-awesome-icon icon="magnifying-glass-plus" />
        </button>
      </div>
    </div>

    <div :id="'details-' + ceadval.id" class="mt-3 pt-2 border-top border-secondary collapse">
      <LoadingSpinner v-if="getCeadvalposLoading" text="... Positionsdaten werden geladen ..." />

      <div v-else-if="!getCeadvalposLoading && positionen.length == 0 && ceadvalposErrorMsg == ''" class="alert alert-info">
        Es wurden keine Positionen gefunden
      </div>

      <div v-else-if="!getCeadvalposLoading && positionen.length > 0 && ceadvalposErrorMsg == ''">
        <div class="row mt-2">
          <div class="col-1"><strong>Pos</strong></div>
          <div class="col-3"><strong>Artikel</strong></div>
          <div class="col-1"><strong>Anzahl</strong></div>
          <div class="col-1"><strong>Alk %</strong></div>
          <div class="col-1"><strong>Brutto [Kg]</strong></div>
          <div class="col-1"><strong>Netto [Kg]</strong></div>
          <div class="col-4"></div>
        </div>

        <div v-for="(pos, idx) in positionen" :key="pos.id + '-' + idx" class="row mb-3">
          <div class="col-1">{{ pos.eadbodyBodyrecorduniqueref }}</div>
          <div class="col-3">{{ pos.eadbodyBrandnameofproducts }}</div>
          <div class="col-1">{{ pos.eadbodyQuantity }}</div>
          <div class="col-1">{{ pos.eadbodyAlcoholicstrength }}</div>
          <div class="col-1">{{ pos.eadbodyGrossmass }}</div>
          <div class="col-1">{{ pos.eadbodyNetmass }}</div>
          <div class="col-4"></div>
        </div>
      </div>

      <div v-else class="alert alert-danger">
        Es ist ein Fehler aufgetreten. Bitte probieren Sie es später erneut.
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from "vue";
import { useStore } from '@/store'
import { ActionTypes as ZelosActionTypes } from "@/store/modules/Zelos/actions";
import { CEadVal, CEadValPos} from "@/models/Zelos/ZelosModels"

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { prop } from "vue-class-component";


export default defineComponent({
  name: "CEadValrow",
  props: {
    ceadval: {
      type: Object as PropType<CEadVal>,
      required: true
    }
  },

  emits: ['toggleTimer'],

  components: {
    LoadingSpinner,
  },

  setup(props) {
    const store = useStore();
    const getCeadvalLoading = computed(() => store.getters.status.getCeadvalLoading)
    const currentLoading = ref(false)

    const authUser = computed(() => store.getters.authUser)
    
    const zeitstempelConverter = computed(() => {
      const timestamp = new Date(props.ceadval.lastmessagetimestamp || new Date());
      return ("0" + timestamp.getDate()).slice(-2) + "." + ("0" + (timestamp.getMonth() + 1)).slice(-2) + "." + timestamp.getFullYear() + " " + ("0" + (timestamp.getHours())).slice(-2) + ":" + ("0" + (timestamp.getMinutes())).slice(-2) + ":" + ("0" + (timestamp.getSeconds())).slice(-2);
    })
    
    const invoicedateConverter = computed(() => {
      const timestamp = new Date(props.ceadval.eadesadInvoicedate || new Date());
      return ("0" + timestamp.getDate()).slice(-2) + "." + ("0" + (timestamp.getMonth() + 1)).slice(-2) + "." + timestamp.getFullYear();
    })

    const positionen = computed(() => store.getters.ceadvalpos.filter((p: CEadValPos) => p.cEadValId == props.ceadval.id))
    const getCeadvalposLoading = computed(() => store.getters.status.getCeadvalposLoading);
    const ceadvalposErrorMsg = computed(() => store.getters.status.ceadvalposErrorMsg);


    const componente = computed(() => store.getters.archivComponente);
    const comploaded = ref(false)

    const statusConverter = computed(() => {
      let status = "undefined"
      switch(props.ceadval.status) {
        case 0: status = "Offen"; break;
        case 1: status = "Gesendet"; break;
        case 2: status = "Genehmigt"; break;
        case 3: status = "Abgelehnt"; break;
        case 4: status = "Annulliert"; break;
        case 5: status = "Abgebrochen"; break;
      }

      return status;
    })

    const statusBGClass = computed(() => {
      let status = "bg-light"
      switch(props.ceadval.status) {
        case 0: status = "alert alert-light"; break;
        // case 1: status = "bg-warning bg-opacity-50"; break;
        case 1: status = "alert alert-warning"; break;
        case 2: status = "alert alert-success"; break;
        case 3: status = "alert alert-danger"; break;
        case 4: status = "alert alert-danger"; break;
        case 5: status = "alert alert-danger"; break;
      }

      return status;
    })

    return {
      store,
      getCeadvalLoading,
      currentLoading,
      comploaded,
      authUser,
      zeitstempelConverter,
      invoicedateConverter,
      componente,
      statusConverter,
      statusBGClass,
      positionen,
      getCeadvalposLoading,
      ceadvalposErrorMsg
    }
  },

  methods: {
    getCEadValPos() {
      if (this.positionen.length == 0) {
        this.store.dispatch(ZelosActionTypes.GetCEadValPosByCEadValId, this.ceadval.id)
      }
    },

  },
})
</script>

<style lang="scss" scoped>
/* .entwurf:hover {
  color: #d99728;
  cursor: pointer;
} */
</style>