
<template>
  <div :id="'pdfViewerWrapper-' + docId" class="bg-secondary d-flex flex-column flex-grow h-100">
    
    <div class="px-2 d-flex bg-secondary border-bottom border-primary">
      <div class="d-flex flex-row align-items-center" :class="!options.showPagesOption ? 'd-none' : ''">
        <span class="text-white">Seiten: {{ numPages }}</span>
      </div>

      <div class="d-flex flex-row align-items-center mx-auto" :class="!options.showScalingOption ? 'd-none' : ''">
        <div class="btn-group">
          <button class="btn btn-secondary" @click="zoomOut()" :disabled="scale <= 0.1">
            <font-awesome-icon icon="minus"/>
          </button>
          <button class="btn btn-secondary" @click="zoomIn()" :disabled="scale >= 2">
            <font-awesome-icon icon="plus" />
          </button>
        </div>
        <span class="text-white ms-2">{{ Math.floor(scale * 100) }}%</span>
      </div>  

      <div>
        <button class="btn btn-secondary" title="Drucken" @click="printPDF()" :class="!options.showPrintOption ? 'd-none' : ''">
          <font-awesome-icon icon="print"  class="p-1 fa-lg"/>
        </button>
        <button class="btn btn-secondary" title="Herunterladen" @click="saveInFileSystem()" :class="!options.showDownloadOption ? 'd-none' : ''">
          <font-awesome-icon icon="file-download" class="p-1 fa-lg"/>
        </button>
        <button class="btn btn-secondary" title="An E-Mail senden" @click="sendAsEmail()" :class="!options.showEmailSendenOption ? 'd-none' : ''">
          <font-awesome-icon icon="envelope" class="p-1 fa-lg"/>
        </button>
      </div>
    </div>

    <div id="outerContainer" class="pb-2 bg-secondary h-100">
      <div id="mainContainer">
        <div class="viewerContainer">
          <div :id="'viewer-' + docId" class="pdfViewer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, render, PropType } from 'vue';
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/web/pdf_viewer.css";
import { SimpleLinkService, DownloadManager } from 'pdfjs-dist/web/pdf_viewer';
import { PDFViewerOptions } from './PDFViewerClasses'

pdfjsLib.GlobalWorkerOptions.workerPort = new Worker(new URL('pdfjs-dist/build/pdf.worker.js', import.meta.url))


export default defineComponent({
  name: "PDFViewer",
  props: {
    options: {
      type: Object as PropType<PDFViewerOptions>,
      default: new PDFViewerOptions()
    },
    pdfUrl: {
      type: String,
      required: true
    },
    docId: {
      type: String,
      default: ""
    },
    pdfName: {
      type: String,
      default: ""
    },
  },

  setup () {
    // const pdfDoc = ref();
    const pdfDoc: any = undefined;

    return {
      pdfDoc,
    }
  },

  data () {
    return {
      wrapperWidth: 0,
      wrapperHeight: 0,
      numPages: 0,
      pdfloading: false,
      currentScale: 0,
      scale: 0,
    }
  },

  methods: {
    async zoomIn () {
      if (this.scale + 0.1 < 2) {
        this.scale += 0.1
        await this.updateViewer()
      }
      else {
        this.scale = 2
        await this.updateViewer()
      }
    },
    
    async zoomOut () {
      if (this.scale - 0.1 > 0.1) {
        this.scale -= 0.1;
        await this.updateViewer();
      }
      else {
        this.scale = 0.1;
        await this.updateViewer();
      }
    },

    async updateViewer () {
      this.clearViewer()
      await this.loadPDF()
    },

    transformDate(datum: Date): string {
      if (datum == null) {
        return ""
      }

      const date: Date = new Date(datum.toString());

      return ("0" + date.getDate()).slice(-2) + "." + ("0" + (date.getMonth() + 1)).slice(-2) + "." + date.getFullYear();
    },

    async loadPDF() {
      this.pdfloading = true;
      const viewer = document.getElementById("viewer-" + this.docId) as HTMLDivElement;

      try {
        this.pdfDoc = await pdfjsLib.getDocument(this.pdfUrl).promise;
        this.numPages = this.pdfDoc.numPages

        for (let pageNum = 1; pageNum <= this.pdfDoc.numPages; pageNum++) {
          const page = await this.pdfDoc.getPage(pageNum);
          const pdfPageWidth = page.view[2]
          const pdfPageHeight = page.view[3]

          if (this.options.defaultScale != 0) {
            this.scale = this.options.defaultScale
          }
          else {
            if (this.scale == 0) {
              this.scale = Math.min(this.wrapperWidth / pdfPageWidth, this.wrapperHeight / pdfPageHeight);
            }
          }
          
          const viewport = page.getViewport({ scale: this.scale });

          viewer.style.setProperty('--scale-factor',   viewport.scale.toString());
          
          // Create a container for the canvas and text layer
          const pageDiv = document.createElement('div');
          pageDiv.classList.add('page');
          pageDiv.classList.add('border-0');
          pageDiv.classList.add('my-2');
          // pageDiv.style.setProperty('--scale-factor', scaleProp.toString());
          pageDiv.style.height = `${viewport.height}px`;
          pageDiv.style.width = `${viewport.width}px`;

          // Set the --scale-factor CSS variable to match viewport.scale
          viewer.appendChild(pageDiv);
          
          const textLayer = document.createElement('div');
          textLayer.classList.add("textLayer")
          pageDiv.appendChild(textLayer);

          const annotationLayer = document.createElement("div")
          annotationLayer.classList.add("annotationLayer")
          // annotationLayer.style.setProperty('--scale-factor', viewport.scale.toString());
          pageDiv.appendChild(annotationLayer);

          const canvasDiv = document.createElement("div")
          canvasDiv.classList.add("canvasWrapper")
          const canvas = document.createElement('canvas');
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          canvasDiv.appendChild(canvas)
          pageDiv.appendChild(canvasDiv);

          const canvasContext = canvas.getContext('2d') as CanvasRenderingContext2D;

          const textLayerDiv = textLayer;
          textLayerDiv.style.height = `${viewport.height}px`;
          textLayerDiv.style.width = `${viewport.width}px`;

          const renderContext = {
            canvasContext,
            viewport
          }
          const renderTask = page.render(renderContext);
          
          const textContent = await page.getTextContent();
          pdfjsLib.renderTextLayer({
            textContentSource: textContent,
            container: textLayerDiv,
            viewport,
            textDivs: [],
          });

          // Render annotations
          const annotationsData = await page.getAnnotations()

          const downloadManager = new DownloadManager()
          const linkService = new SimpleLinkService()
          const pdfAnnotationLayer =  new pdfjsLib.AnnotationLayer({
            div: annotationLayer,
            page,
            viewport
          })

          await pdfAnnotationLayer.render({
            annotations: annotationsData,
            // renderInteractiveForms: true,
            linkService,
            downloadManager,
            annotationStorage: this.pdfDoc.annotationStorage
          })

          await renderTask.promise;
          this.pdfloading = false;
        }
      } catch (error) {
        this.pdfloading = false;
        console.error('Error loading the PDF:', error);
      }
    },

    async saveInFileSystem() {
      const blobUrl = await this.saveOrDownloadPDF()
      const a = document.createElement("a");
      if (!a.click) {
        throw new Error('DownloadManager: "a.click()" is not supported.');
      }
      a.href = blobUrl;
      a.target = "_parent";
      if ("download" in a) {
        a.download = this.pdfName;
      }
      (document.body || document.documentElement).append(a);
      a.click();
      a.remove();
    },

    async printPDF() {
      const blobUrl = await this.saveOrDownloadPDF();
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = blobUrl

      const viewer = document.getElementById("viewer-" + this.docId) as HTMLDivElement;
      viewer.appendChild(iframe)
      
      iframe.onload = () => {
        iframe.contentWindow?.print()
      }
    },

    async sendAsEmail () {
      this.$emit("sendDocAsEmail")
    },

    async saveOrDownloadPDF () {
      let blob = new Blob()
      if (this.pdfDoc.annotationStorage.size == 0) {
        blob = await this.downloadPDF()
      }
      else {
        blob = await this.savePDF()
      }

      const blobUrl = URL.createObjectURL(blob);
      return blobUrl
    },

    async downloadPDF () {
      const data = await this.pdfDoc.getData();
      const blob = new Blob([data], {
        type: "application/pdf"
      });

      return blob;
    },

    async savePDF () {
      const data = await this.pdfDoc.saveDocument();
      const blob = new Blob([data], {
        type: "application/pdf"
      });

      return blob;

    },

    clearViewer() {
      const viewer = document.getElementById("viewer-" + this.docId)
      if (viewer != null && viewer != undefined) {
        viewer.innerHTML = ""
      }
    }
  },

  mounted() {
    const wrapper = document.getElementById('pdfViewerWrapper-' + this.docId);
    const wrapperParent = wrapper?.parentElement as HTMLDivElement
    wrapperParent.style.width = "100%"
    wrapperParent.style.height = "100%"
    this.wrapperWidth = wrapperParent?.clientWidth || window.innerWidth;
    // this.wrapperHeight = wrapperParent?.clientHeight || window.innerHeight;
    this.wrapperHeight = window.innerHeight * 0.9;

    const viewer = document.getElementById("viewer-" + this.docId)
    if (viewer?.innerHTML == "") {
      this.loadPDF();
    }
  },
  
  updated () {
    const viewer = document.getElementById("viewer-" + this.docId)
    if (viewer?.innerHTML == "" && !this.pdfloading) {
      this.loadPDF();
    }
  }
})
</script>


<style>
#outerContainer {
  /* height: 100% !important; */
  overflow: hidden;
}

.annotationLayer .textWidgetAnnotation :is(input, textarea):required, .annotationLayer .choiceWidgetAnnotation select:required, .annotationLayer .buttonWidgetAnnotation:is(.checkBox, .radioButton) input:required {
  outline: 1px solid #F1A82C;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
    border-radius: 0;
}

.page {
  overflow: hidden !important;
}
</style>